import React, { useState, useEffect } from "react";
import "./AllTransactions.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import axios from "axios";

const AllTransactions = ({ userId }) => {
  const [transactionData, setTransactionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          ` https://backend.shnsm1.com/api/fund-transfers/user/${userId}`
        );
        setTransactionData(response.data);
        setFilteredData(response.data); // Initialize filtered data
      } catch (err) {
        setError("There are no transactions to display at this time.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  // Search filter functionality
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter transaction data based on search query
    const filtered = transactionData.filter(
      (transaction) =>
        transaction.description.toLowerCase().includes(query.toLowerCase()) ||
        transaction.amount.toString().includes(query)
    );

    setFilteredData(filtered);
  };

  // Export data to Excel
  const exportToExcel = () => {
    const data = filteredData.map((row, index) => ({
      "Sr No.": index + 1,
      "Desc.": row.description,
      Date: row.date,
      Deduction: `TDS: ${row.tds}%\nSurcharge: ${row.surcharge}%`,
      Amount: `${row.amount} ₹`,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");
    worksheet["!cols"] = [
      { wch: 10 },
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 10 },
    ];
    XLSX.writeFile(workbook, "UserTransactions.xlsx");
  };

  return (
    <div className="transaction-page">
      <div className="header">
        <h2>User Transactions</h2>
      </div>

      <div className="table-section">
        {isLoading ? (
          <div className="loading">
            <FontAwesomeIcon icon={faSpinner} spin /> Loading transactions...
          </div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : (
          <>
            <div className="table-controls">
              <button className="export-btn" onClick={exportToExcel}>
                <FontAwesomeIcon icon={faFileExport} /> Export to Excel
              </button>
              <div className="search-filter">
                <label htmlFor="search">Search:</label>
                <input
                  type="text"
                  id="search"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>

            {/* Table Format */}
            <table className="transaction-table">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Description</th>
                  <th>Date</th>
                  <th>Deduction</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((row, index) => (
                    <tr key={row.id}>
                      <td>{index + 1}</td>
                      <td>{row.description}</td>
                      <td>
                        {new Date(row.transactionDate).toLocaleDateString()}
                      </td>
                      <td>
                        TDS: {row.tds}% | Surcharge: {row.surcharge}%
                      </td>
                      <td>{row.amount} ₹</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="no-data">
                      No data available in table
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Card Format */}
            <div className="transaction-cards">
              {filteredData.length > 0 ? (
                filteredData.map((row, index) => (
                  <div key={row.id} className="transaction-card">
                    <div className="card-header">
                      <h4>Transaction #{index + 1}</h4>
                      <p className="date">
                        {new Date(row.transactionDate).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="card-body">
                      <p>
                        <strong>Description:</strong> {row.description}
                      </p>
                      <p>
                        <strong>Deduction:</strong> TDS: {row.tds}% | Surcharge:{" "}
                        {row.surcharge}%
                      </p>
                      <p>
                        <strong>Amount:</strong> {row.amount} ₹
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-data">No data available in table</div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AllTransactions;
