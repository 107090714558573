import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import * as XLSX from "xlsx"; // For Excel export
import { StateContext } from "../../../contexts/ContextProvider"; // Context for logged-in user
import LevelTree from "../subleveltree/Subleveltree"; // Level tree component
import "./MyTeam1.css"; // CSS for styling

const AdminMyTeam = () => {
  const { userId } = useContext(StateContext); // Get the logged-in admin's ID from context
  const [users, setUsers] = useState([]); // State for all users
  const [teamMembers, setTeamMembers] = useState([]); // State for team members
  const [filteredUsers, setFilteredUsers] = useState([]); // State for filtered users
  const [currentView, setCurrentView] = useState("main"); // State for current view
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [selectedUserId, setSelectedUserId] = useState(null); // State for selected user ID
  const [selectedUserName, setSelectedUserName] = useState(""); // State for selected user's name
  const [bankDetails, setBankDetails] = useState({}); // State for bank details

  // Fetch users and their bank details from the server
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          " https://backend.shnsm1.com/api/users/data/all"
        );
        const allUsers = response.data;
        setUsers(allUsers);
        setFilteredUsers(allUsers); // Set initial filtered users
        setTeamMembers(allUsers); // Set initial team members

        // Fetch bank details for each user
        allUsers.forEach((user) => {
          fetchBankDetails(user.userId);
        });
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [userId]);

  // Fetch bank details for a specific user
  const fetchBankDetails = async (userId) => {
    try {
      const response = await axios.get(
        ` https://backend.shnsm1.com/api/bank-details/${userId}`
      );
      setBankDetails((prevDetails) => ({
        ...prevDetails,
        [userId]: response.data,
      }));
    } catch (error) {
      console.error("Error fetching bank details:", error);
    }
  };

  // Function to get the user status
  const getStatus = (user) => {
    if (user.blocked) return "Inactive";
    if (user.active) return "Active";
    return "N/A";
  };

  // Function to get users under a specific referral ID (Downline)
  const getDownlineUsers = (referralId) => {
    const currentLevelUsers = users.filter(
      (user) => user.referralId === referralId
    );
    let allDownlineUsers = [...currentLevelUsers];

    currentLevelUsers.forEach((user) => {
      allDownlineUsers = [
        ...allDownlineUsers,
        ...getDownlineUsers(user.userId),
      ];
    });

    return allDownlineUsers;
  };

  // Function to handle 'Downline' view
  const handleDownlineView = (userId, userName) => {
    const downlineUsers = getDownlineUsers(userId);
    setTeamMembers(downlineUsers);
    setSelectedUserId(userId);
    setSelectedUserName(userName);
    setCurrentView("downline");
  };

  // Function to handle 'Tree View'
  const handleTreeView = (userId, userName) => {
    setSelectedUserId(userId);
    setSelectedUserName(userName);
    setCurrentView("tree");
  };

  // Export team members to Excel
  const exportToExcel = () => {
    const data = teamMembers.map((user, index) => ({
      "Sr. No.": index + 1,
      "Login ID": user.userId || "N/A",
      Name: user.userName || "N/A",
      "Phone No.": user.phoneNumber || "N/A",
      "Referral ID": user.referralId || "N/A",
      DOJ: formatDate(user.dateOfJoining),
      Location: user.address || "N/A",
      "Wallet Balance": user.walletBalance || "0",
      Status: getStatus(user),
      "Account No": bankDetails[user.userId]?.accountNumber || "N/A",
      "Account Holder Name":
        bankDetails[user.userId]?.accountHolderName || "N/A",
      "Bank Name": bankDetails[user.userId]?.bankName || "N/A",
      "Branch Name": bankDetails[user.userId]?.branchName || "N/A",
      "IFSC Code": bankDetails[user.userId]?.ifscCode || "N/A",
      "Google Pay Number": bankDetails[user.userId]?.gpayNumber || "N/A",
      "PhonePe Number": bankDetails[user.userId]?.phonePeNumber || "N/A",
      "UPI ID": bankDetails[user.userId]?.upiId || "N/A",
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "AllUsersData");
    XLSX.writeFile(workbook, "AllUsersData.xlsx");
  };

  // Format date to DD-MM-YYYY
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  // Search/filter users by name, user ID, referral ID, or location
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term) {
      const filtered = users.filter(
        (user) =>
          user.userName?.toLowerCase().includes(term) ||
          user.userId?.toLowerCase().includes(term) ||
          user.address?.toLowerCase().includes(term)
      );
      setFilteredUsers(filtered);
      setTeamMembers(filtered);
    } else {
      setFilteredUsers(users);
      setTeamMembers(users);
    }
  };

  // Function to reset to the main view
  const handleGoToMainMenu = () => {
    setTeamMembers(users);
    setCurrentView("main");
    setSelectedUserId(null);
    setSelectedUserName("");
  };

  return (
    <div className="admin-team-container">
      <header className="admin-team-header">
        <h2 className="h21">Admin - My Profile</h2>
        {selectedUserName && (
          <h3>
            Viewing {currentView === "tree" ? "Tree" : "Downline"} of:{" "}
            {selectedUserName}
          </h3>
        )}
      </header>

      <div className="admin-filter-container">
        <div className="admin-filter-options">
          <input
            type="text"
            className="admin-search-input"
            placeholder="Search by name, user ID, referral ID, or location..."
            value={searchTerm}
            onChange={handleSearch}
          />
          &nbsp;&nbsp;
          <button className="admin-excel-button" onClick={exportToExcel}>
            Export to Excel
          </button>
        </div>
      </div>

      {currentView === "tree" ? (
        <div>
          <LevelTree users={filteredUsers} userId={selectedUserId} />
          <button onClick={handleGoToMainMenu} className="admin-back-button">
            Go to Main Menu
          </button>
        </div>
      ) : currentView === "downline" ? (
        <div>
          {teamMembers.length > 0 ? (
            <table className="admin-team-details-table">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Owner</th>
                  <th>Statistics</th>
                  <th>Wallet Balance</th>
                  <th>Bank Details</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {teamMembers.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <p>
                        <b>Login ID:</b> {user.userId || "N/A"}
                      </p>
                      <p>
                        <b>Name:</b> {user.userName || "N/A"}
                      </p>
                      <p>
                        <b>Email:</b> {user.email || "N/A"}
                      </p>
                      <p>
                        <b>Phone No.:</b> {user.phoneNumber || "N/A"}
                      </p>
                      <p>
                        <b>Location:</b> {user.address || "N/A"}
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>DOJ:</b> {formatDate(user.dateOfJoining)}
                      </p>
                      <b>Status :</b>{" "}
                      <span
                        className={user.blocked ? "status-red" : "status-green"}
                      >
                        {user.blocked ? "Inactive" : "Active"}
                      </span>
                    </td>
                    <td>
                      <p>
                        <b>
                          {user.walletBalance
                            ? ` ₹ ${user.walletBalance} `
                            : "0"}
                        </b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>Account No:</b>{" "}
                        {bankDetails[user.userId]?.accountNumber || "N/A"}
                      </p>
                      <p>
                        <b>Account Holder Name:</b>{" "}
                        {bankDetails[user.userId]?.accountHolderName || "N/A"}
                      </p>
                      <p>
                        <b>Bank Name:</b>{" "}
                        {bankDetails[user.userId]?.bankName || "N/A"}
                      </p>
                      <p>
                        <b>Branch Name:</b>{" "}
                        {bankDetails[user.userId]?.branchName || "N/A"}
                      </p>
                      <p>
                        <b>IFSC Code:</b>{" "}
                        {bankDetails[user.userId]?.ifscCode || "N/A"}
                      </p>
                      <p>
                        <b>Google Pay Number:</b>{" "}
                        {bankDetails[user.userId]?.gpayNumber || "N/A"}
                      </p>
                      <p>
                        <b>PhonePe Number:</b>{" "}
                        {bankDetails[user.userId]?.phonePeNumber || "N/A"}
                      </p>
                      <p>
                        <b>UPI ID:</b>{" "}
                        {bankDetails[user.userId]?.upiId || "N/A"}
                      </p>
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          handleDownlineView(user.userId, user.userName)
                        }
                      >
                        Downline
                      </button>
                      &nbsp;&nbsp;
                      <button
                        onClick={() =>
                          handleTreeView(user.userId, user.userName)
                        }
                      >
                        Tree View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No Data Found</p>
          )}
          <button onClick={handleGoToMainMenu} className="admin-back-button">
            Go to Main Menu
          </button>
        </div>
      ) : (
        <div>
          {teamMembers.length > 0 ? (
            <table className="admin-team-details-table">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Owner</th>
                  <th>Statistics</th>
                  <th>Wallet Balance</th>
                  <th>Bank Details</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {teamMembers.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <p>
                        <b>Login ID:</b> {user.userId || "N/A"}
                      </p>
                      <p>
                        <b>Name:</b> {user.userName || "N/A"}
                      </p>
                      <p>
                        <b>Email:</b> {user.email || "N/A"}
                      </p>
                      <p>
                        <b>Phone No.:</b> {user.phoneNumber || "N/A"}
                      </p>
                      <p>
                        <b>Location:</b> {user.address || "N/A"}
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>DOJ:</b> {formatDate(user.dateOfJoining)}
                      </p>
                      <b>Status :</b>{" "}
                      <span
                        className={user.blocked ? "status-red" : "status-green"}
                      >
                        {user.blocked ? "Inactive" : "Active"}
                      </span>
                    </td>
                    <td>
                      <p>
                        <b>
                          {user.walletBalance ? `₹ ${user.walletBalance}` : "0"}
                        </b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>Account No:</b>{" "}
                        {bankDetails[user.userId]?.accountNumber || "N/A"}
                      </p>
                      <p>
                        <b>Account Holder Name:</b>{" "}
                        {bankDetails[user.userId]?.accountHolderName || "N/A"}
                      </p>
                      <p>
                        <b>Bank Name:</b>{" "}
                        {bankDetails[user.userId]?.bankName || "N/A"}
                      </p>
                      <p>
                        <b>Branch Name:</b>{" "}
                        {bankDetails[user.userId]?.branchName || "N/A"}
                      </p>
                      <p>
                        <b>IFSC Code:</b>{" "}
                        {bankDetails[user.userId]?.ifscCode || "N/A"}
                      </p>
                      <p>
                        <b>Google Pay Number:</b>{" "}
                        {bankDetails[user.userId]?.gpayNumber || "N/A"}
                      </p>
                      <p>
                        <b>PhonePe Number:</b>{" "}
                        {bankDetails[user.userId]?.phonePeNumber || "N/A"}
                      </p>
                      <p>
                        <b>UPI ID:</b>{" "}
                        {bankDetails[user.userId]?.upiId || "N/A"}
                      </p>
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          handleDownlineView(user.userId, user.userName)
                        }
                      >
                        Downline
                      </button>
                      &nbsp;&nbsp;
                      <button
                        onClick={() =>
                          handleTreeView(user.userId, user.userName)
                        }
                      >
                        Tree View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No Data Found</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminMyTeam;
