import React, { useState } from 'react';
import './RewardMatching.css'; // Import the CSS file

const RewardMatching = () => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div className="rm-container">
      <h3 className="rm-heading">Reward_matching Income</h3>
      <div className="rm-breadcrumb">Wallet / E Wallet</div>

      {/* Filter section */}
      <div className="rm-filter-section">
        <button className="rm-filter-btn">Filter</button>
      </div>

      {/* Wallet Summary */}
      <div className="rm-wallet-summary">
        <table>
          <thead>
            <tr>
              <th>CREDIT</th>
              <th>DEBIT</th>
              <th>AVAILABLE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="rm-text-success">+ 0 ₹</td>
              <td className="rm-text-danger">- 0 ₹</td>
              <td className="rm-text-primary">0 ₹</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Export & Search */}
      <div className="rm-export-search">
        <button className="rm-export-btn">Excel Export</button>
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="rm-search-input"
        />
      </div>

      {/* Table Data */}
      <div className="rm-data-table">
        <table>
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Owner</th>
              <th>Desc.</th>
              <th>Date</th>
              <th>Deduction</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="6">No data available in table</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="rm-pagination">
        <button className="rm-page-btn">Previous</button>
        <button className="rm-page-btn">Next</button>
      </div>
    </div>
  );
};

export default RewardMatching;