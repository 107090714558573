// src/components/admin/news/AddNewsModal.js
import React, { useState } from "react";
import axios from "axios";

const AddNewsModal = ({ closeModal }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("image", image);

    try {
      await axios.post(" https://backend.shnsm1.com/api/news/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      closeModal(true);
    } catch (err) {
      console.error("Error adding news:", err);
      setError(
        err.response?.data?.message || "Error adding news. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Add News</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              disabled={loading}
            />
          </div>

          <div className="form-group">
            <label>Description:</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              disabled={loading}
            />
          </div>

          <div className="form-group">
            <label>Image:</label>
            <input
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              accept="image/*"
              required
              disabled={loading}
            />
          </div>

          <div className="button-group">
            <button type="submit" disabled={loading}>
              {loading ? "Adding..." : "Add News"}
            </button>
            <button
              type="button"
              onClick={() => closeModal(false)}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewsModal;
