import React, { useContext } from 'react';
import { StateContext } from '../../../contexts/ContextProvider';
import './WelcomeLetter.css';

const WelcomeLetter = () => {
  const {
    userId,
    userName,
    email,
    phoneNumber,
    referralId,
  } = useContext(StateContext);

  return (
    <div className="welcome-letter-container">
      <header className="header">
        <h2>Welcome Letter</h2>
      </header>
      <div className="letter-content">
        <h3>SHNSM-I</h3>
        <p><strong>Name :</strong> {userName}</p>
        <p><strong>User ID :</strong> {userId}</p>
        <p><strong>Referral ID :</strong> {referralId}</p>
        <p><strong>Mobile No. :</strong> {phoneNumber}</p>
        <p><strong>E-Mail :</strong> {email}</p>
        <p>
          You have made the right decision at the right time and chosen the great 
          <strong> SHNSM-I </strong> that has offered you great avenues of financial 
          freedom that you have been seeking for so long. You are now part of the opportunity 
          of the Million. Our Business is an exciting people's business. A business that has the 
          potential to turn your dreams into reality. It will be our joint responsibility to realize 
          your destiny of making you as well as your people successful.
        </p>

        <p>
          We once again appreciate you for selecting <strong>SHNSM-I</strong> to fulfill your wealth 
          and health needs. Your enthusiasm, commitment, and sincerity of each and every consultant 
          to succeed and help others to succeed in this challenging world.
        </p>

        <p>
          We would be happy to see your dreams come true with <strong>SHNSM-I</strong> and make 
          millions with our powerful business plan.
        </p>

        <p>Thanking You,</p>
        <p><strong>SHNSM-I</strong></p>
      </div>
    </div>
  );
};

export default WelcomeLetter;
