import React, { useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";

const BlockUnblock = () => {
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  // Fetch all users
  const fetchAllUsers = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        " https://backend.shnsm1.com/api/users/data/all"
      );
      if (!response.ok) throw new Error("Failed to fetch users");
      const data = await response.json();
      setUsers(data);
      setError(null);
    } catch (err) {
      setError("Error fetching users: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch users initially and at regular intervals (every 20 seconds)
    fetchAllUsers();
    const intervalId = setInterval(fetchAllUsers, 20000); // Refresh every 20 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  // Block or unblock a user manually
  const blockUser = async (userId, name, currentBlockedStatus) => {
    const action = currentBlockedStatus ? "unBlock" : "block";

    if (window.confirm(`Are you sure you want to ${action} ${name}?`)) {
      try {
        const response = await fetch(
          ` https://backend.shnsm1.com/api/users/${userId}/${action}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ blocked: !currentBlockedStatus }),
          }
        );

        if (!response.ok) throw new Error(`Failed to ${action} user`);

        const updatedUsers = users.map((user) =>
          user._id === userId
            ? { ...user, blocked: !currentBlockedStatus }
            : user
        );

        setUsers(updatedUsers);
        setSuccessMessage(`User successfully ${action}ed`);

        setTimeout(() => setSuccessMessage(""), 3000);
      } catch (err) {
        setError(`Error ${action}ing user: ` + err.message);
      }
    }
  };

  // Filter users based on search and filter criteria
  const filteredUsers = users.filter((user) => {
    const matchesFilter =
      filter === "Blocked"
        ? user.blocked
        : filter === "Un-Blocked"
        ? !user.blocked
        : true;

    const matchesSearch =
      user.userName?.toLowerCase().includes(search.toLowerCase()) ||
      user.email?.toLowerCase().includes(search.toLowerCase()) ||
      user.phoneNumber?.includes(search);

    return matchesFilter && matchesSearch;
  });

  return (
    <div className="body2">
      <div style={{ padding: "24px", backgroundColor: "#fff" }}>
        <header style={{ marginBottom: "24px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <FaHome style={{ width: "20px", height: "20px" }} />
            <h2
              style={{
                fontSize: "1.5rem",
                fontWeight: "600",
                color: "#000000",
              }}
            >
              Block / Un-Block Users
            </h2>
          </div>
        </header>

        {error && (
          <div
            style={{
              padding: "12px",
              marginBottom: "16px",
              backgroundColor: "#fff",
              color: "#000000",
              borderRadius: "4px",
            }}
          >
            {error}
          </div>
        )}

        {successMessage && (
          <div
            style={{
              padding: "12px",
              marginBottom: "16px",
              backgroundColor: "#fff",
              color: "#000000",
              borderRadius: "4px",
            }}
          >
            {successMessage}
          </div>
        )}

        <div
          style={{
            display: "flex",
            gap: "16px",
            marginBottom: "24px",
            flexWrap: "wrap",
          }}
        >
          <input
            type="text"
            placeholder="Search by name, email, or phone"
            style={{
              flex: 1,
              padding: "8px 16px",
              border: "1px solid #e5e7eb",
              borderRadius: "4px",
              minWidth: "200px",
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <select
            style={{
              padding: "8px 16px",
              border: "1px solid #e5e7eb",
              borderRadius: "4px",
            }}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="">All Users</option>
            <option value="Blocked">Blocked Users</option>
            <option value="Un-Blocked">UnBlocked Users</option>
          </select>
        </div>

        {loading ? (
          <div style={{ textAlign: "center", padding: "32px" }}>
            Loading users...
          </div>
        ) : (
          <div style={{ overflowX: "auto" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ backgroundColor: "#fff", color: "#000000" }}>
                  <th
                    style={{
                      padding: "16px",
                      textAlign: "left",
                      border: "1px solid #e5e7eb",
                    }}
                  >
                    Sr No.
                  </th>
                  <th
                    style={{
                      padding: "16px",
                      textAlign: "left",
                      border: "1px solid #e5e7eb",
                    }}
                  >
                    User Details
                  </th>
                  <th
                    style={{
                      padding: "16px",
                      textAlign: "left",
                      border: "1px solid #e5e7eb",
                    }}
                  >
                    Status
                  </th>
                  <th
                    style={{
                      padding: "16px",
                      textAlign: "left",
                      border: "1px solid #e5e7eb",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user, index) => (
                  <tr
                    key={user.userId}
                    style={{ backgroundColor: "#fff", color: "#000000" }}
                  >
                    <td
                      style={{ padding: "16px", border: "1px solid #e5e7eb" }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{ padding: "16px", border: "1px solid #e5e7eb" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <div>
                          <strong>ID:</strong> {user.userId}
                        </div>
                        <div>
                          <strong>Name:</strong> {user.userName}
                        </div>
                        <div>
                          <strong>Phone:</strong> {user.phoneNumber}
                        </div>
                        <div>
                          <strong>Email:</strong> {user.email}
                        </div>
                      </div>
                    </td>
                    <td
                      style={{ padding: "16px", border: "1px solid #e5e7eb" }}
                    >
                      <span
                        style={{
                          padding: "4px 8px",
                          borderRadius: "4px",
                          backgroundColor: user.blocked ? "#fee2e2" : "#dcfce7",
                          color: user.blocked ? "#dc2626" : "#16a34a",
                        }}
                      >
                        {user.blocked ? "Blocked" : "Active"}
                      </span>
                    </td>
                    <td
                      style={{ padding: "16px", border: "1px solid #e5e7eb" }}
                    >
                      <button
                        onClick={() =>
                          blockUser(user._id, user.userName, user.blocked)
                        }
                        style={{
                          padding: "8px 16px",
                          backgroundColor: user.blocked ? "#22c55e" : "#dc2626",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        {user.blocked ? "Unblock" : "Block"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlockUnblock;
