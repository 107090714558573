import p1_img from './aata.jpg'
import p2_img from './bath.jpg'
import p3_img from './surf.jpg'
import p4_img from './juice.jpg'
import p5_img from './skin.jpg'
import p6_img from './maggie.jpg'
import p7_img from './spices.png'
import p8_img from './pooja_essentials.png'

let data_product = [
  {
    id:1,
    name:"Atta, Rice, Oils & Dals",
    image:p1_img,    
  },

  {id:2,
    name:"Body & Bath",
    image:p2_img,
  },

  {
    id:3,
    name:"Cleaning Essentials",
    image:p3_img,    
  },

  {
    id:4,
    name:"Bevarages",
    image:p4_img,
    
  },

  {
    id:5,
    name:"Skin Care",
    image:p5_img,
   
  },
  {
    id:6,
    name:"Packaged Foods",
    image:p6_img,
    
  },
  {
    id:7,
    name:"Spices",
    image:p7_img,
    
  },
  {
    id:8,
    name:"Pooja Essentials",
    image:p8_img,
    
  },
];

export default data_product;