// News.js (updated version)
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./News.css";
import AddNewsModal from "./AddNewsModal";
import EditNewsModal from "./EditNewsModal";
import ImageModal from "./ImageModal";
import * as XLSX from "xlsx";

const News = () => {
  const [newsList, setNewsList] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchNews();
  }, []);

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, newsList]);

  const fetchNews = async () => {
    try {
      const res = await axios.get(" https://backend.shnsm1.com/api/news");
      setNewsList(res.data);
      setError(null);
    } catch (err) {
      console.error("Error fetching news:", err);
      setError("Failed to fetch news");
    }
  };

  const handleSearch = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") {
      setFilteredNews(newsList);
    } else {
      const filtered = newsList.filter(
        (news) =>
          news.title.toLowerCase().includes(lowercasedValue) ||
          news.description.toLowerCase().includes(lowercasedValue)
      );
      setFilteredNews(filtered);
    }
  };

  const exportToExcel = () => {
    try {
      // Prepare data for export
      const exportData = filteredNews.map((news, index) => ({
        "Sr No.": index + 1,
        Title: news.title,
        Description: news.description,
        Date: new Date(news.date).toLocaleDateString(),
        "Image URL": ` https://backend.shnsm1.com/uploads/${news.imageUrl
          .split("/")
          .pop()}`,
      }));

      // Create worksheet
      const ws = XLSX.utils.json_to_sheet(exportData);

      // Create workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "News");

      // Generate Excel file
      XLSX.writeFile(
        wb,
        `News_Report_${new Date().toISOString().split("T")[0]}.xlsx`
      );
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      setError("Failed to export Excel file");
    }
  };

  const handleAddNews = () => {
    setShowAddModal(true);
  };

  const handleEditNews = (news) => {
    setSelectedNews(news);
    setShowEditModal(true);
  };

  const handleDeleteNews = async (newsId) => {
    if (window.confirm("Are you sure you want to delete this news item?")) {
      try {
        await axios.delete(` https://backend.shnsm1.com/api/news/${newsId}`);
        fetchNews();
      } catch (err) {
        console.error("Error deleting news:", err);
        setError("Failed to delete news");
      }
    }
  };

  const handleImageClick = (news) => {
    setSelectedImage(news);
    setShowImageModal(true);
  };

  const closeAddModal = (success = false) => {
    setShowAddModal(false);
    if (success) {
      fetchNews();
    }
  };

  const closeEditModal = (success = false) => {
    setShowEditModal(false);
    setSelectedNews(null);
    if (success) {
      fetchNews();
    }
  };

  const closeImageModal = () => {
    setShowImageModal(false);
    setSelectedImage(null);
  };

  return (
    <div className="news-container">
      <div className="header-container">
        <h2>News Management</h2>
        <div className="actions-container">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search news..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>
          <button className="btn-add" onClick={handleAddNews}>
            Add News
          </button>
          <button className="btn-excel" onClick={exportToExcel}>
            <i className="fas fa-file-excel"></i> Export to Excel
          </button>
        </div>
      </div>

      {error && <div className="error-message">{error}</div>}
      <table>
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Title</th>
            <th>Description</th>
            <th>Image</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredNews.map((news, index) => (
            <tr key={news._id}>
              <td>{index + 1}</td>
              <td>{news.title}</td>
              <td>{news.description}</td>
              <td>
                <img
                  src={` https://backend.shnsm1.com/uploads/${news.imageUrl
                    .split("/")
                    .pop()}`}
                  alt={news.title}
                  className="news-image clickable"
                  onClick={() => handleImageClick(news)}
                  onError={(e) => {
                    e.target.src = "placeholder-image-url";
                    e.target.alt = "Image not found";
                  }}
                />
              </td>
              <td>{new Date(news.date).toLocaleDateString()}</td>
              <td>
                <button
                  className="btn-edit"
                  onClick={() => handleEditNews(news)}
                >
                  Edit
                </button>
                <button
                  className="btn-delete"
                  onClick={() => handleDeleteNews(news._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showAddModal && <AddNewsModal closeModal={closeAddModal} />}
      {showEditModal && (
        <EditNewsModal news={selectedNews} closeModal={closeEditModal} />
      )}
      {showImageModal && selectedImage && (
        <ImageModal
          imageUrl={` https://backend.shnsm1.com/uploads/${selectedImage.imageUrl
            .split("/")
            .pop()}`}
          title={selectedImage.title}
          onClose={closeImageModal}
        />
      )}
    </div>
  );
};

export default News;
