import React, { useState, useEffect } from "react";
import axios from "axios";
import "./News.css";

const News = () => {
  const [newsList, setNewsList] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const res = await axios.get(" https://backend.shnsm1.com/api/news");
      setNewsList(res.data);
      setError(null);
    } catch (err) {
      console.error("Error fetching news:", err);
      setError("Failed to fetch news");
    }
  };

  return (
    <div id="news">
      <div className="grid-container">
        {error && <div className="error-message">{error}</div>}

        {/* Conditionally render the title if there are news items */}
        {newsList.length > 0 && <h1 className="updated-news">NEWS</h1>}

        <div className="news-grid">
          {newsList.map((item) => (
            <div key={item._id} className="news-card">
              <div className="image-container">
                <img
                  src={` https://backend.shnsm1.com/uploads/${item.imageUrl
                    .split("/")
                    .pop()}`}
                  alt={item.title}
                  className="card-image"
                  onError={(e) => {
                    e.target.src = "placeholder-image-url"; // Replace with your actual placeholder image URL
                    e.target.alt = "Image not found";
                  }}
                />
              </div>
              <div className="card-content">
                <h3 className="card-title">{item.title}</h3>
                <p className="card-description">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default News;
