import React, { useEffect, useState } from "react";
import { FaFileExcel, FaArrowLeft, FaArrowRight, FaHome } from "react-icons/fa";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import "./BalanceReport.css";

const BalanceReport = () => {
  const [balanceData, setBalanceData] = useState([]); // All fetched data
  const [filteredData, setFilteredData] = useState([]); // Data after applying the search filter
  const [searchQuery, setSearchQuery] = useState(""); // Search input state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          " https://backend.shnsm1.com/api/users/data/all"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        console.log("Fetched data:", data); // Log the data to see the structure
        setBalanceData(data);
        setFilteredData(data); // Initialize the filtered data
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Handle search functionality
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase(); // Convert to lowercase for case-insensitive search
    setSearchQuery(query);

    // Filter the balance data based on userId
    const filtered = balanceData.filter((item) =>
      item.userId.toLowerCase().includes(query)
    );
    setFilteredData(filtered);
  };

  // Function to export data to Excel
  const exportToExcel = () => {
    const formattedData = filteredData.map((item, index) => ({
      "Sr No.": index + 1,
      Owner: `ID: ${item.userId || "-"}\nName: ${
        item.userName || "-"
      }\nPhone: ${item.phoneNumber || "-"}\nEmail: ${item.email || "-"}`,
      "Available Balance": item.walletBalance || "-",
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "BalanceReport");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, "BalanceReport.xlsx");
  };

  return (
    <div className="balance-report">
      <div className="balance-header">
        <h2>Balance Report</h2>
      </div>

      <div className="breadcrumb">
        <span>
          <FaHome /> / Balance Report
        </span>
      </div>

      <div className="report-content-box">
        <div className="top-bar">
          <button className="excel-export-button" onClick={exportToExcel}>
            <FaFileExcel className="icon" /> Excel Export
          </button>
          <input
            type="text"
            className="search-bar"
            placeholder="Search by User ID..."
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>

        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <table className="balance-table">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Owner</th>
                <th>Available Balance</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <div className="owner-info">
                      <div>ID: {data.userId || "-"}</div>
                      <div>Name: {data.userName || "-"}</div>
                      <div>Phone No.: {data.phoneNumber || "-"}</div>
                      <div>Email: {data.email || "-"}</div>
                    </div>
                  </td>
                  <td>{data?.walletBalance || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <div className="pagination">
          <span>
            Showing {filteredData.length} of {balanceData.length} entries
          </span>
          <div className="pagination-buttons">
            <button className="previous-button" disabled>
              <FaArrowLeft className="icon" /> Previous
            </button>
            <button className="next-button" disabled>
              Next <FaArrowRight className="icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceReport;
