import React, { useContext, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography } from "@mui/material";
import "react-pro-sidebar/dist/css/styles.css";
import { FaRegCreditCard, FaUserCheck } from "react-icons/fa";
import { RiPagesLine } from "react-icons/ri";
import { BiSolidReport } from "react-icons/bi";
import { HiCurrencyRupee } from "react-icons/hi2";
import { MdAccountBalance, MdMessage, MdContacts, MdPayments, MdBlock } from "react-icons/md";
import { FaGift } from "react-icons/fa6";
import { ImNewspaper } from "react-icons/im";
import { GrAchievement } from "react-icons/gr";
import { IoSettingsSharp } from "react-icons/io5";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import GroupsIcon from '@mui/icons-material/Groups';
import logo from '../../../Assets/Shnsm1.png';
import { StateContext } from "../../../contexts/ContextProvider";

const Item = ({ title, to, icon, selected, setSelected, onClick }) => (
  <MenuItem
    active={selected === title}
    style={{ color: "#ffffff" }}
    onClick={() => {
      setSelected(title);
      if (onClick) {
        onClick();
      }
    }}
    icon={icon}
  >
    <Typography>{title}</Typography>
    {!onClick && <Link to={to} />}
  </MenuItem>
);

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const { setFlag } = useContext(StateContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    setFlag(0); // Clear session data
    navigate('/');
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": { background: `#1b133a !important` },
        "& .pro-icon-wrapper": { backgroundColor: "transparent !important" },
        "& .pro-inner-item": { padding: "5px 35px 5px 20px !important" },
        "& .pro-inner-item:hover": { color: "#868dfb !important" },
        "& .pro-menu-item.active": { color: "#6870fa !important" },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* Sidebar Toggle Button */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{ margin: "10px 0 20px 0", color: "#ffffff" }}
          >
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {/* Profile Section */}
          {!isCollapsed && (
            <Box mb="25px" textAlign="center">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img alt="profile-user" width="100px" height="100px" src={logo} style={{ cursor: "pointer", borderRadius: "50%" }} />
              </Box>
              <Typography variant="h2" color="#ffffff" fontWeight="bold" sx={{ m: "10px 0 0 0" }}>
                SHNSM - I
              </Typography>
            </Box>
          )}

          {/* Menu Items */}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item title="Dashboard" to="/adashboard" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />

            <Typography variant="h6" color="#cccccc" sx={{ m: "15px 0 5px 20px" }}>ECOMMERCE</Typography>
            <Item title="Shop" to="/shop" icon={<LocalGroceryStoreIcon />} selected={selected} setSelected={setSelected} />

            <Typography variant="h6" color="#cccccc" sx={{ m: "15px 0 5px 20px" }}>NETWORK</Typography>
            <Item title="Level Tree" to="/leveltree1" icon={<GroupsIcon />} selected={selected} setSelected={setSelected} />
            <Item title="My Team" to="/myteam1" icon={<GroupsIcon />} selected={selected} setSelected={setSelected} />

            <Typography variant="h6" color="#cccccc" sx={{ m: "15px 0 5px 20px" }}>E-WALLET</Typography>
            <Item title="All Transactions" to="/ewallet" icon={<AccountBalanceWalletIcon />} selected={selected} setSelected={setSelected} />
            <Item title="Credit Fund" to="/creditdebit" icon={<FaRegCreditCard />} selected={selected} setSelected={setSelected} />
            <Item title="Fund Request" to="/fundrequest1" icon={<TimelineOutlinedIcon />} selected={selected} setSelected={setSelected} />

            <Typography variant="h6" color="#cccccc" sx={{ m: "15px 0 5px 20px" }}>ACTION</Typography>
            <Item title="Block/Un-Block" to="/block" icon={<MdBlock />} selected={selected} setSelected={setSelected} />

            <Typography variant="h6" color="#cccccc" sx={{ m: "15px 0 5px 20px" }}>REPORTS</Typography>
            <Item title="Activate Report" to="/activatereport" icon={<BiSolidReport />} selected={selected} setSelected={setSelected} />
            <Item title="Balance Report" to="/balancereport" icon={<MdAccountBalance />} selected={selected} setSelected={setSelected} />
            <Item title="Contact Us Report" to="/contactus" icon={<MdContacts />} selected={selected} setSelected={setSelected} />

            <Typography variant="h6" color="#cccccc" sx={{ m: "15px 0 5px 20px" }}>PAYMENT</Typography>
            <Item title="Payout" to="/payout" icon={<MdPayments />} selected={selected} setSelected={setSelected} />

           

            <Typography variant="h6" color="#cccccc" sx={{ m: "15px 0 5px 20px" }}>ADD ON</Typography>
            <Item title="News" to="/news" icon={<ImNewspaper />} selected={selected} setSelected={setSelected} />
            <Item title="Achiever" to="/AchieversTable" icon={<GrAchievement />} selected={selected} setSelected={setSelected} />

            <Typography variant="h6" color="#cccccc" sx={{ m: "15px 0 5px 20px" }}>DESTROY SESSION</Typography>
            <Item title="Logout" onClick={handleLogout} icon={<LogoutIcon />} selected={selected} setSelected={setSelected} />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
