import React, { useState, useEffect } from "react";
import "./ContactUsReport.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const ContactUsReport = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);

  const fetchAllMessages = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        " https://backend.shnsm1.com/api/contact/AllMessages"
      );
      if (!response.ok) throw new Error("Failed to fetch messages");
      const data = await response.json();
      setMessages(data);
      setError(null);
    } catch (err) {
      setError("Error fetching messages: " + err.message);
      toast.error("Failed to fetch messages");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (id) => {
    setMessageToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setMessageToDelete(null);
    setDeleteDialogOpen(false);
  };

  const deleteMessage = async () => {
    if (!messageToDelete) return;

    try {
      const response = await fetch(
        ` https://backend.shnsm1.com/api/contact/deleteMessageByID/${messageToDelete}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete message");
      }

      // Remove the deleted message from state
      setMessages(
        messages.filter((message) => message._id !== messageToDelete)
      );
      toast.success("Message deleted successfully");
    } catch (err) {
      console.error("Error deleting message:", err);
      toast.error("Failed to delete message");
    } finally {
      setDeleteDialogOpen(false);
      setMessageToDelete(null);
    }
  };

  useEffect(() => {
    fetchAllMessages();
  }, []);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  const handleExport = () => {
    const csvContent = messages.map((item) => ({
      Name: item.name,
      Email: item.email,
      SUbject: item.subject,
      Message: item.message,
    }));

    const csvString = [
      Object.keys(csvContent[0]).join(","),
      ...csvContent.map((row) => Object.values(row).join(",")),
    ].join("\n");

    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Contact us Report.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="contact-us-report">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <div className="header">
        <h2>Contact Us Report</h2>
        <button className="export-btn" onClick={handleExport}>
          Excel Export
        </button>
      </div>

      <table>
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile No.</th>
            <th>Message</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {messages.map((item, index) => (
            <tr key={item._id}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td className="contactReport-td">{item.subject}</td>
              <td className="contactReport-td">{item.message}</td>
              <td>
                {new Date(item.createdAt).toLocaleDateString("en-GB", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </td>
              <td>
                <button
                  className="delete-btn"
                  onClick={() => handleDeleteClick(item._id)}
                >
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this message? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={deleteMessage} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ContactUsReport;
