import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../contexts/ContextProvider";
import axios from "axios";
import { FaUserFriends, FaDollarSign, FaUsers, FaLink } from "react-icons/fa";
import "./Dashboard.css";

const Dashboard = () => {
  const { userId, setUserId } = useContext(StateContext);
  const [userName, setUserName] = useState("");
  const [balance, setBalance] = useState(0);
  const [teamCount, setTeamCount] = useState(0);
  const [directCount, setDirectCount] = useState(0);
  const [referralLink, setReferralLink] = useState("");
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [withdrawalSum, setWithdrawalSum] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await axios.get(
          ` https://backend.shnsm1.com/api/users/${userId}`
        );
        setUserName(userResponse.data.userName);
        setUserId(userResponse.data.userId);
        setBalance(userResponse.data.walletBalance || 0);
        setReferralLink(`https://shnsm1.com/app/r/${userResponse.data.userId}`);

        const allUsersResponse = await axios.get(
          " https://backend.shnsm1.com/api/users/data/all"
        );
        const allUsers = allUsersResponse.data;

        const directReferrals = allUsers.filter(
          (user) => user.referralId === userId
        );
        setDirectCount(directReferrals.length);

        const allLevels = getAllLevels(userId, allUsers);
        setTeamCount(allLevels.length);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId, setUserId]);

  useEffect(() => {
    const fetchTotalWithdrawal = async () => {
      try {
        const response = await axios.get(
          ` https://backend.shnsm1.com/api/fund-transfers/user/${userId}`
        );
        const debitTransactions = response.data.filter(
          (transaction) => transaction.method === "debit"
        );

        const sum = debitTransactions.reduce(
          (acc, transaction) => acc + transaction.amount,
          0
        );
        setWithdrawalData(debitTransactions);
        setWithdrawalSum(sum);
      } catch (error) {
        console.error("Error fetching withdrawal data:", error);
      }
    };

    fetchTotalWithdrawal();
  }, [userId]);

  const getAllLevels = (referralId, allUsers) => {
    const currentLevelUsers = allUsers.filter(
      (user) => user.referralId === referralId
    );
    let allLevels = [...currentLevelUsers];

    currentLevelUsers.forEach((user) => {
      const lowerLevels = getAllLevels(user.userId, allUsers);
      allLevels = [...allLevels, ...lowerLevels];
    });

    return allLevels;
  };

  return (
    <div className="dashboard">
      <h2>Dashboard</h2>

      <div className="referral-section">
        <label>Referral Link</label>
        <input type="text" value={referralLink} readOnly />
        <button onClick={() => navigator.clipboard.writeText(referralLink)}>
          <FaLink className="icon" /> Copy
        </button>
      </div>

      <div className="stats-section">
        <div className="stat-card">
          <FaUsers className="icon" />
          <h3>{teamCount}</h3>
          <p>My Team</p>
        </div>
        <div className="stat-card">
          <FaUserFriends className="icon" />
          <h3>{directCount}</h3>
          <p>My Direct</p>
        </div>
        <div className="stat-card">
          <FaDollarSign className="icon" />
          <h3>₹{balance.toFixed(2)}</h3>
          <p>Available Balance</p>
        </div>
      </div>

      <div className="income-section">
        <div className="income-card">
          <FaDollarSign className="icon" />
          <h3>0.00</h3>
          <p>Total Income</p>
        </div>
      </div>

      <div className="withdrawal-activation-container">
        <div className="withdrawals-card">
          <FaDollarSign className="icon" />
          <h3>₹{withdrawalSum.toFixed(2)}</h3>
          <p>Total Withdrawals</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
