import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../contexts/ContextProvider";
import axios from "axios";
import { FaUser, FaCheckCircle, FaTimesCircle, FaBan } from "react-icons/fa";
import "./LevelTree.css";

const LevelTree = () => {
  const { userId, userName, setTeam } = useContext(StateContext);
  const [users, setUsers] = useState([]);
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          " https://backend.shnsm1.com/api/users/data/all"
        );
        const allUsers = response.data;

        // Function to recursively calculate levels
        const calculateLevels = (currentLevelUsers) => {
          if (currentLevelUsers.length === 0) return [];

          const nextLevelUsers = allUsers.filter((user) =>
            currentLevelUsers.some(
              (levelUser) => levelUser.userId === user.referralId
            )
          );

          return [currentLevelUsers, ...calculateLevels(nextLevelUsers)];
        };

        // Start with the root user
        const rootUserId = "swamy520741808";
        const level1Users = allUsers.filter(
          (user) => user.referralId === rootUserId
        );

        const allLevels = calculateLevels(level1Users);

        setLevels(allLevels);

        // Merge all levels into the team array
        setTeam(allLevels.flat());
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchUsers();
  }, [userId]);

  const calculateActiveInactive = (level) => {
    const activeMembers = level.filter((user) => !user.blocked); // Active users are not blocked
    const inactiveMembers = level.filter((user) => user.blocked); // Inactive users are blocked
    return {
      total: level.length,
      active: activeMembers.length,
      inactive: inactiveMembers.length,
    };
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Formats as MM/DD/YYYY or DD/MM/YYYY based on locale
  };

  return (
    <div className="light-level-tree">
      <div className="main-user">
        <div className="main-user-card">
          <FaUser className="user-icon main-icon" />
          <div className="user-details">
            <p className="user-id">ID: swamy520741808</p>
            <p className="user-name">Name: SWAMY</p>
          </div>
        </div>
      </div>

      {levels.map((level, levelIndex) => {
        const { total, active, inactive } = calculateActiveInactive(level);

        return (
          <div key={levelIndex} className="light-level">
            <div className="level-info">
              <h3 className="level-title">LEVEL {levelIndex + 1}</h3>
              <p className="level-stats">Total: {total}</p>
              <p className="level-stats">
                Active: <FaCheckCircle className="status-icon" /> {active}
              </p>
              <p className="level-stats">
                Inactive: <FaTimesCircle className="status-icon" /> {inactive}
              </p>
            </div>

            <div className="user-list">
              {level.map((user, index) => (
                <div
                  key={index}
                  className={`user-card ${user.blocked ? "blocked-user" : ""}`}
                >
                  <FaUser className="user-icon" />
                  <div className="user-details">
                    <p className="user-id">ID: {user.userId}</p>
                    <p className="user-name">Name: {user.userName}</p>
                    <p className="user-referral-id">
                      Referral ID: {user.referralId}
                    </p>
                    <p className="user-joining-date">
                      Date of Joining: {formatDate(user.dateOfJoining)}
                    </p>
                    {user.blocked ? (
                      <FaBan
                        className="status-icon blocked-icon"
                        title="Blocked"
                      />
                    ) : (
                      <FaCheckCircle
                        className="status-icon active-icon"
                        title="Active"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LevelTree;
