import React, { useContext, useState, useEffect } from "react";
import "./LoginSignup.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { StateContext } from "../../../contexts/ContextProvider";
import { useLocation, useNavigate } from "react-router-dom";

function LoginSignup() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const {
    setUserId,
    setUserName,
    setEmail,
    setPhoneNumber,
    setReferralId,
    setFlag,
  } = useContext(StateContext);

  const [isActive, setIsActive] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [email, setEmailInput] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [formData, setFormData] = useState({
    referralId: "",
    userId: "SH-", // Fixed prefix for userId
    userName: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    address: "",
  });

  const [loginData, setLoginData] = useState({
    userId: "SH-", // Fixed prefix for login userId
    password: "",
  });

  useEffect(() => {
    // Check if there's a reset token in the URL and trigger reset password flow
    const resetTokenParam = queryParams.get("resetToken");
    if (resetTokenParam) {
      setResetToken(resetTokenParam);
      setResetPassword(true);
    }
  }, [location.search]);

  const clearUserData = () => {
    setUserId("");
    setUserName("");
    setEmail("");
    setPhoneNumber("");
    setReferralId("");
  };

  const updateUserData = (data) => {
    setUserId(data.userId);
    setUserName(data.userName);
    setEmail(data.email);
    setPhoneNumber(data.phoneNumber);
    setReferralId(data.referralId);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUserIdChange = (e) => {
    const input = e.target.value;
    // Ensure the input always starts with "SH-"
    if (input.startsWith("SH-")) {
      setFormData({ ...formData, userId: input });
    }
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;

    // Ensure the input always starts with "SH-"
    if (name === "userId") {
      if (!value.startsWith("SH-")) {
        return; // Prevent any edit that removes the prefix
      }
    }

    setLoginData({ ...loginData, [name]: value });
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        " https://backend.shnsm1.com/api/users/forgot-password",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        }
      );
      const data = await response.json();

      if (response.ok) {
        alert("Reset password email sent successfully!");
        navigate("/reset-password");
        setForgotPassword(false);
      } else {
        alert(data.message || "Failed to send reset password email");
      }
    } catch (err) {
      console.error("Error sending reset password email:", err);
      alert("Failed to send reset password email");
    }
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      alert("Passwords do not match!");
      return;
    }

    try {
      const response = await fetch(
        ` https://backend.shnsm1.com/api/users/reset-password/${resetToken}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            newPassword,
            confirmPassword: confirmNewPassword,
          }),
        }
      );
      const data = await response.json();

      if (response.ok) {
        alert("Password reset successful!");
        setResetPassword(false);
        setIsActive(false);
        navigate("/login"); // Redirect to login after successful reset
      } else {
        alert(data.message || "Failed to reset password");
      }
    } catch (err) {
      console.error("Error resetting password:", err);
      alert("Failed to reset password");
    }
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    try {
      const response = await fetch(
        " https://backend.shnsm1.com/api/users/signup",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();

      if (response.ok) {
        clearUserData();
        updateUserData(data);
        alert("Registration successful");
        setIsActive(false);
      } else {
        alert(data.message || "Registration failed");
      }
    } catch (err) {
      console.error("Error during signup:", err);
      alert("Registration failed");
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    if (loginData.userId === "SH-Admin" && loginData.password === "786+369") {
      clearUserData();
      setFlag(3);
      navigate("/adashboard");
      return;
    }

    try {
      const response = await fetch(
        " https://backend.shnsm1.com/api/users/login",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(loginData),
        }
      );
      const data = await response.json();

      if (response.ok) {
        clearUserData();
        updateUserData(data);
        setFlag(2);
        navigate("/dashboard");
      } else {
        alert(data.message || "Login failed");
      }
    } catch (err) {
      console.error("Error during login:", err);
      alert("Login failed");
    }
  };

  return (
    <div className="unique-loginCon" id="login">
      {forgotPassword ? (
        <div className="modern-form-container">
          <form className="modern-form" onSubmit={handleForgotPasswordSubmit}>
            <h1 className="form-title">Forgot Password</h1>
            <p className="form-instructions">
              Enter your registered email address, and we'll send you a link to
              reset your password.
            </p>
            <input
              type="email"
              className="form-input"
              placeholder="Enter your registered email"
              value={email}
              onChange={(e) => setEmailInput(e.target.value)}
              required
            />
            <div className="button-container">
              <button type="submit" className="primary-button">
                Send Reset Email
              </button>
              <button
                type="button"
                className="secondary-button"
                onClick={() => setForgotPassword(false)}
              >
                Back to Login
              </button>
            </div>
          </form>
        </div>
      ) : resetPassword ? (
        <div className="unique-form-container">
          <form onSubmit={handleResetPasswordSubmit}>
            <h1>Reset Password</h1>
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Confirm New Password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required
            />
            <button type="submit">Reset Password</button>
            <button type="button" onClick={() => setResetPassword(false)}>
              Back to Login
            </button>
          </form>
        </div>
      ) : (
        <div
          className={`unique-login-container ${isActive ? "active" : ""}`}
          id="unique-container"
        >
          <div className="unique-form-container unique-sign-up">
            <form onSubmit={handleSignUpSubmit}>
              <h1>Create Account</h1>
              <input
                type="text"
                placeholder="Referral ID"
                name="referralId"
                value={formData.referralId}
                onChange={handleChange}
                required
              />
              <fieldset className="responsive-fieldset">
                <legend className="responsive-legend">User ID :</legend>
                <input
                  type="text"
                  placeholder="SH-"
                  name="userId"
                  className="responsive-input"
                  value={formData.userId}
                  onChange={handleUserIdChange}
                  required
                  onFocus={(e) => {
                    if (!e.target.value) {
                      setFormData({ ...formData, userId: "SH-" });
                    }
                  }}
                />
              </fieldset>

              <input
                type="text"
                placeholder="Username"
                name="userName"
                value={formData.userName}
                onChange={handleChange}
                required
              />
              <input
                type="tel"
                placeholder="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input
                type="password"
                placeholder="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <input
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                placeholder="Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
              <button type="submit">Sign Up</button>
            </form>
          </div>
          <div className="unique-form-container unique-sign-in">
            <form onSubmit={handleLoginSubmit}>
              <h1>LOGIN</h1>
              <fieldset>
                <legend className="responsive-legend">User ID :</legend>
                <input
                  type="text"
                  placeholder="User ID (must start with SH-)"
                  name="userId"
                  value={loginData.userId}
                  onChange={handleLoginChange}
                  required
                />
              </fieldset>
              <input
                type="password"
                placeholder="Password"
                name="password"
                value={loginData.password}
                onChange={handleLoginChange}
                required
              />
              <p
                style={{
                  color: "#1b133a",
                  fontWeight: "600",
                  cursor: "pointer",
                  textDecoration: "underline",
                  marginTop: "10px",
                }}
                onClick={() => setForgotPassword(true)}
              >
                Forgot Password?
              </p>
              <button type="submit">LOGIN</button>
            </form>
          </div>
          <div className="unique-toggle-container">
            <div className="unique-toggle">
              <div className="unique-toggle-panel unique-toggle-left">
                <h1>Welcome Back!</h1>
                <button onClick={() => setIsActive(false)}>LOGIN</button>
              </div>
              <div className="unique-toggle-panel unique-toggle-right">
                <h1>Hello, Friend!</h1>
                <p>
                  Register with your personal details to use all of the site's
                  features
                </p>
                <button id="register" onClick={() => setIsActive(true)}>
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginSignup;
