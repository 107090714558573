import React, { useEffect, useState } from "react";
import { FaFileExcel, FaHome } from "react-icons/fa";
import { saveAs } from "file-saver";
import "./ActivateReport.css";

const ActivateReport = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const fetchAllUsers = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        " https://backend.shnsm1.com/api/users/data/all"
      );
      if (!response.ok) throw new Error("Failed to fetch users");
      const data = await response.json();
      setUsers(data);
      setFilteredUsers(data); // Initialize filtered users
      setError(null);
    } catch (err) {
      setError("Error fetching users: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  // Filter by date range
  const handleFilter = () => {
    if (!startDate || !endDate) {
      alert("Please select both start and end dates for filtering.");
      return;
    }
    const filtered = users.filter((user) => {
      const joiningDate = new Date(user.dateOfJoining);
      return (
        joiningDate >= new Date(startDate) && joiningDate <= new Date(endDate)
      );
    });
    setFilteredUsers(filtered);
  };

  // Reset filters
  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setSearchQuery("");
    setFilteredUsers(users); // Reset to all users
  };

  // Search by User ID
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const searchedUsers = users.filter((user) =>
      user.userId.toLowerCase().includes(query)
    );
    setFilteredUsers(searchedUsers);
  };

  // CSV Export Function
  const exportToCSV = () => {
    const csvHeader = [
      "Sr No.,User ID,User Name,Referral ID,Phone Number,Email,Date of Joining,Wallet Balance",
    ];
    const csvRows = filteredUsers.map(
      (user, index) =>
        `${index + 1},${user.userId},${user.userName},${user.referralId},${
          user.phoneNumber
        },${user.email},${formatDate(user.dateOfJoining)},${user.walletBalance}`
    );
    const csvContent = [csvHeader, ...csvRows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "ActivateReport.csv");
  };

  return (
    <div className="activate-report">
      <div className="report-header">
        <h2>Activate Report</h2>
      </div>

      <div className="breadcrumb">
        <span>
          <FaHome /> / Activate Report
        </span>
      </div>

      <div className="filter-bar">
        <button className="filter-button" onClick={toggleFilter}>
          Filter
        </button>
        {showFilter && (
          <div className="filter-container">
            <div className="filter-inputs">
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="filter-date"
                placeholder="Start Date"
              />
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="filter-date"
                placeholder="End Date"
              />
            </div>
            <div className="filter-buttons">
              <button className="submit-button" onClick={handleFilter}>
                Submit
              </button>
              <button className="reset-button" onClick={handleReset}>
                Reset
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="report-content-box">
        <div className="summary-bar">
          <div className="summary-item">
            <span>Total Amount</span>
            <span>₹ 2620</span>
          </div>
        </div>

        <div className="top-bar">
          <button className="excel-export-button" onClick={exportToCSV}>
            <FaFileExcel /> Excel Export
          </button>
          <input
            type="text"
            className="search-bar"
            placeholder="Search by User ID..."
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>

        <table className="activate-table">
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>User ID</th>
              <th>User Name</th>
              <th>Referral ID</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Date of Joining</th>
              <th>Wallet Balance</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="8">Loading...</td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan="8">{error}</td>
              </tr>
            ) : filteredUsers.length > 0 ? (
              filteredUsers.map((user, index) => (
                <tr key={user._id}>
                  <td>{index + 1}</td>
                  <td>{user.userId}</td>
                  <td>{user.userName}</td>
                  <td>{user.referralId}</td>
                  <td>{user.phoneNumber}</td>
                  <td>{user.email}</td>
                  <td>{formatDate(user.dateOfJoining)}</td>
                  <td>₹ {user.walletBalance}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No users found</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="pagination">
          <span>
            Showing 1 to {filteredUsers.length} of {filteredUsers.length}{" "}
            entries
          </span>
        </div>
      </div>
    </div>
  );
};

export default ActivateReport;
