import React from 'react';
import './Product.css';
import data_product from '../../../Assets/data';

const Products = () => {
  return (
    <div className='products'>
      <h1>OUR PRODUCTS</h1>
      
      <div className='productitem'>
        {data_product.map((item, i) => (
          <div className='product' key={i}>
            <img src={item.image} alt={item.name} />
            <p>{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
