import React, { useState } from "react";
import "./AboutUs.css";
import productVideo from "../../../Assets/aboutvideo.mp4"; // Ensure this is the correct path to your video file

const AboutUs = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="about-us" id="about">
      <h1 className="title-heading">ABOUT US</h1>
      <div className="container">
        <section className="about">
          <div className="about-image">
            {/* Replace img with video */}
            <video className="about-video" src={productVideo} alt="furniture showcase" controls />
          </div>
          <div className="para">
            <p>
              At Samrat Home Needs Pvt Limited, our story is one of craftsmanship, creativity, and a deep commitment to transforming living spaces into works of art. Founded with a vision to redefine furniture shopping.
              {isExpanded && (
                <>
                  {" "}
                  our team of skilled artisans and designers pour their passion into every creation, resulting in furniture that's more than just functional; it's an expression of individuality. We believe that your home should be a reflection of your personality. Quality is at the core of everything we do. From the selection of premium materials to the meticulous attention to detail, we strive for excellence in every piece we offer. Sustainability is equally important to us, and we're committed to sourcing responsibly and reducing our environmental footprint.
                </>
              )}
              <span className="read-more" onClick={toggleReadMore}>
                {isExpanded ? " readless" : " readmore..."}
              </span>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
