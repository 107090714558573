import React, { useState } from "react";
import { FaEye, FaFileExcel, FaHome } from "react-icons/fa";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import "./IncomeReport.css";

const IncomeReport1 = () => {
  const [showFilter, setShowFilter] = useState(false);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  // Data for the table
  const incomeData = [
    { srNo: 1, date: "2024-04-20", levelIncome: "-", rewardIncome: "-" },
    { srNo: 2, date: "2024-04-17", levelIncome: "40.5", rewardIncome: "-" },
    { srNo: 3, date: "2024-04-16", levelIncome: "-", rewardIncome: "-" },
  ];

  const exportToExcel = () => {
    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(incomeData);

    // Create a new workbook and add the worksheet to it
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "IncomeReport");

    // Generate a buffer for the workbook
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Save the file
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, "IncomeReport.xlsx");
  };

  return (
    <div className="income-report">
      <div className="report-header">
        <h2>Income Report</h2>
      </div>

      <div className="breadcrumb">
        <span><FaHome/> / Income Report</span>
      </div>

      <div className="filter-bar">
        <button className="filter-button" onClick={toggleFilter}>
          Filter
        </button>
        {showFilter && (
          <div className="filter-container">
            <div className="filter-inputs">
              <input type="checkbox" />
              <input 
                type="date" 
                placeholder="Start Date" 
                className="filter-date" 
              />
              <input 
                type="date" 
                placeholder="End Date" 
                className="filter-date" 
              />
            </div>
            <div className="note">
              <span>Note: Checkbox Required For Filter</span>
            </div>
            <div className="filter-buttons">
              <button className="submit-button">Submit</button>
              <button className="reset-button">Reset</button>
            </div>
          </div>
        )}
      </div>

      <table className="summary-table">
        <thead>
          <tr>
            <th>Total Level Income</th>
            <th>Total Reward Matching Income</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>40.5</td>
            <td>-</td>
          </tr>
        </tbody>
      </table>

      <div className="report-content-box">
        <div className="top-bar">
          <button className="excel-export-button" onClick={exportToExcel}> 
            <FaFileExcel /> Excel Export
          </button>
          <input type="text" className="search-bar" placeholder="Search..." />
        </div>

        <table className="income-table">
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Date</th>
              <th>Level Income</th>
              <th>Reward Matching Income</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {incomeData.map((item) => (
              <tr key={item.srNo}>
                <td>{item.srNo}</td>
                <td>{item.date}</td>
                <td>{item.levelIncome}</td>
                <td>{item.rewardIncome}</td>
                <td><FaEye className="action-icon" /></td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <span>Showing 1 to {incomeData.length} of {incomeData.length} entries</span>
          <div className="pagination-buttons">
            <button className="previous-button">Previous</button>
            <button className="next-button">Next</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeReport1;
