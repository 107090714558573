import React from 'react';
import './SubLevelTree.css'

const LevelTree = ({ users, userId }) => {
  // Render the level tree for the given user ID
  const renderLevel = (currentUserId) => {
    const currentLevelUsers = users.filter(user => user.referralId === currentUserId);

    if (currentLevelUsers.length === 0) {
      return null; // No further users at this level
    }

    return (
      <ul className="level-list">
        {currentLevelUsers.map((user, index) => (
          <li key={index} className="level-item">
            <div className="levelTree-userCard">
              <div className="user-info">
                <div className="user-avatar">👤</div>
                <div className="user-details">
                  <p>ID: {user.userId}</p>
                  <p>Name: {user.userName}</p>
                </div>&nbsp;&nbsp;&nbsp;
              </div>
            </div>
            {/* Recursively render next level users */}
            {renderLevel(user.userId)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="level-tree-container">
      <header className="header">
        <h2>Level Tree for User {userId}</h2>
      </header>

      {renderLevel(userId) || <p>No users found under this user.</p>}
    </div>
  );
};

export default LevelTree;
