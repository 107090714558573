import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

function ResetPassword() {
  const { resetToken } = useParams(); // Capture reset token from URL
  const navigate = useNavigate(); // For navigation after successful reset

  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(""); // Clear the error message on input change
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (formData.newPassword !== formData.confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    try {
      // API call to reset password
      const response = await fetch(
        ` https://backend.shnsm1.com/api/users/reset-password/${resetToken}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData), // Send new passwords
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSuccess(true);
        alert("Password reset successful! Redirecting to the login page.");
        navigate("/login"); // Redirect to login page
      } else {
        setError(data.message || "Error resetting password");
      }
    } catch (err) {
      setError("Error resetting password. Please try again later.");
    }
  };

  return (
    <div
      className="reset-password-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        fontFamily: "'Arial', sans-serif",
      }}
    >
      <form
        onSubmit={handleResetPasswordSubmit}
        style={{
          backgroundColor: "#fff",
          padding: "2rem",
          borderRadius: "12px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          width: "100%",
          maxWidth: "400px",
          textAlign: "center",
        }}
      >
        <h1 style={{ fontSize: "1.8rem", color: "#333", marginBottom: "1rem" }}>
          Reset Password
        </h1>
        {error && (
          <div
            style={{
              backgroundColor: "#f8d7da",
              color: "#721c24",
              padding: "0.8rem",
              borderRadius: "8px",
              marginBottom: "1rem",
            }}
          >
            {error}
          </div>
        )}
        <input
          type="password"
          placeholder="New Password"
          name="newPassword"
          value={formData.newPassword}
          onChange={handleChange}
          required
          style={{
            width: "100%",
            padding: "0.8rem",
            marginBottom: "1rem",
            border: "1px solid #ccc",
            borderRadius: "8px",
            fontSize: "1rem",
          }}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
          style={{
            width: "100%",
            padding: "0.8rem",
            marginBottom: "1rem",
            border: "1px solid #ccc",
            borderRadius: "8px",
            fontSize: "1rem",
          }}
        />
        <button
          type="submit"
          style={{
            color: "#fff",
            border: "none",
            padding: "0.8rem 1.2rem",
            borderRadius: "8px",
            cursor: "pointer",
            fontSize: "1rem",
            width: "100%",
            marginBottom: "1rem",
          }}
        >
          Reset Password
        </button>
        {success && (
          <p
            className="success-message"
            style={{
              color: "#155724",
              padding: "0.8rem",
              borderRadius: "8px",
            }}
          >
            Password reset successful!
          </p>
        )}
      </form>
    </div>
  );
}

export default ResetPassword;
