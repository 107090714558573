import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from "@mui/icons-material/Image";

const AchieversTable = () => {
  const [achievers, setAchievers] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentAchiever, setCurrentAchiever] = useState({
    id: null,
    name: "",
    description: "",
    image: null,
  });

  const fetchAchievers = async () => {
    try {
      const response = await fetch(
        " https://backend.shnsm1.com/api/achievers/"
      );
      const data = await response.json();
      setAchievers(data);
    } catch (error) {
      console.error("Error fetching achievers:", error);
    }
  };

  useEffect(() => {
    fetchAchievers();
  }, []);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setCurrentAchiever({ id: null, name: "", description: "", image: null });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentAchiever((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (upload) => {
        setCurrentAchiever((prev) => ({
          ...prev,
          image: upload.target.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    try {
      const response = await fetch(
        " https://backend.shnsm1.com/api/achievers/add/",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(currentAchiever),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add");
      }

      const newAchiever = await response.json();
      setAchievers((prev) => [...prev, newAchiever]);
      handleClose();
    } catch (error) {
      console.error("Error during save:", error);
      alert(`Error: ${error.message}`);
    }
  };

  const handleEdit = (achiever) => {
    const achieverToEdit = {
      id: achiever._id,
      name: achiever.name,
      description: achiever.description,
      image: achiever.image,
    };

    setCurrentAchiever(achieverToEdit);
    setEditMode(true);
    handleOpen();
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(
        ` https://backend.shnsm1.com/api/achievers/update/${currentAchiever.id}/`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(currentAchiever),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update");
      }

      await fetchAchievers();
      handleClose();
    } catch (error) {
      console.error("Error during update:", error);
      alert(`Error: ${error.message}`);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        ` https://backend.shnsm1.com/api/achievers/delete/${id}/`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete");
      }

      setAchievers((prev) => prev.filter((achiever) => achiever._id !== id));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const filteredAchievers = achievers.filter((achiever) =>
    achiever.name.toLowerCase().includes("")
  );

  return (
    <Box sx={{ p: 3, backgroundColor: "white", color: "black" }}>
      <Typography variant="h4" gutterBottom>
        Achiever
      </Typography>

      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 2 }}
        onClick={handleOpen}
      >
        Add Achiever
      </Button>

      <TableContainer component={Paper} sx={{ backgroundColor: "white" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "black" }}>Sr. No.</TableCell>
              <TableCell sx={{ color: "black" }}>Name</TableCell>
              <TableCell sx={{ color: "black" }}>Description</TableCell>
              <TableCell sx={{ color: "black" }}>Image</TableCell>
              <TableCell sx={{ color: "black" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAchievers.length > 0 ? (
              filteredAchievers.map((achiever, index) => (
                <TableRow key={achiever._id}>
                  <TableCell sx={{ color: "black" }}>{index + 1}</TableCell>
                  <TableCell sx={{ color: "black" }}>{achiever.name}</TableCell>
                  <TableCell sx={{ color: "black" }}>
                    {achiever.description}
                  </TableCell>
                  <TableCell sx={{ color: "black" }}>
                    {achiever.image ? (
                      <img
                        src={achiever.image}
                        alt="Achiever"
                        width="50"
                        height="50"
                      />
                    ) : (
                      <ImageIcon />
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(achiever)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(achiever._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ color: "black" }}>
                  No Achievers Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ color: "white", backgroundColor: "black" }}>
          {editMode ? "Edit Achiever" : "Add Achiever"}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            fullWidth
            value={currentAchiever.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Description"
            name="description"
            fullWidth
            value={currentAchiever.description}
            onChange={handleChange}
          />
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Upload Image
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleImageUpload}
            />
          </Button>
          {currentAchiever.image && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2">Image Preview:</Typography>
              <img src={currentAchiever.image} alt="Preview" width="100" />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              color: "white",
              backgroundColor: "blue",
              "&:hover": {
                backgroundColor: "darkblue",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={editMode ? handleUpdate : handleSave}
            color="primary"
            variant="contained"
          >
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AchieversTable;
