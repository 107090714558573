import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Kyc.css";

const Kyc = () => {
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalImage, setModalImage] = useState(null); // State for the modal image
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.get(
          " https://backend.shnsm1.com/api/Details/details/"
        );
        setDetails(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, []);

  const handleApprove = async (id) => {
    try {
      await axios.put(` https://backend.shnsm1.com/api/Details/approve/${id}`);
      alert("KYC approved successfully!");
      setDetails(
        details.map((detail) =>
          detail._id === id ? { ...detail, status: "Approved" } : detail
        )
      );
      localStorage.setItem(id, "Approved");
    } catch (err) {
      console.error("Error approving KYC:", err);
      alert("Failed to approve KYC.");
    }
  };

  const handleDecline = async (id) => {
    const reason = prompt("Please provide a reason for declining:");
    if (!reason) return;

    if (window.confirm("Are you sure you want to delete this KYC detail?")) {
      try {
        await axios.delete(
          ` https://backend.shnsm1.com/api/Details/details/${id}`
        );
        alert("KYC declined and deleted successfully!");
        setDetails(details.filter((detail) => detail._id !== id));
        localStorage.setItem(id, "Declined");
      } catch (err) {
        console.error("Error deleting KYC:", err);
        alert("Failed to delete KYC.");
      }
    }
  };

  const openImageModal = (imageUrl) => {
    setModalImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="kyc">
      <h2>KYC Details</h2>
      <ul>
        {details.map((detail) => (
          <li key={detail._id}>
            <h3>{detail.name}</h3>
            <p>Email: {detail.email}</p>
            <p>Phone: {detail.phone}</p>
            <p>Address: {detail.address}</p>
            <p>City: {detail.city}</p>
            <p>State: {detail.state}</p>
            <p>Aadhaar No: {detail.aadhaarNumber}</p>
            <p>PAN No: {detail.panNumber}</p>
            <p>
              Aadhaar File:{" "}
              <button
                onClick={() =>
                  openImageModal(`
                     https://backend.shnsm1.com/uploads/${detail.aadhaarFile}`)
                }
              >
                View
              </button>
            </p>
            <p>
              PAN File:{" "}
              <button
                onClick={() =>
                  openImageModal(`
                     https://backend.shnsm1.com/uploads/${detail.panFile}`)
                }
              >
                View
              </button>
            </p>
            {detail.declineReason && (
              <p>Decline Reason: {detail.declineReason}</p>
            )}
            <div className="kyc-status-buttons">
              <button
                className="kyc-status-btn kyc-approved"
                onClick={() => handleApprove(detail._id)}
              >
                Approve
              </button>
              <button
                className="kyc-status-btn kyc-declined"
                onClick={() => handleDecline(detail._id)}
              >
                Decline
              </button>
            </div>
          </li>
        ))}
      </ul>

      {/* Modal for displaying images */}
      {isModalOpen && (
        <div className="modal">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <img src={modalImage} alt="KYC Document" />
        </div>
      )}
    </div>
  );
};

export default Kyc;
