import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../contexts/ContextProvider";
import axios from "axios";
import LevelTree from "../subleveltree/Subleveltree"; // Import the LevelTree component
import * as XLSX from "xlsx"; // Import SheetJS for Excel export
import "./MyTeam.css";

const MyTeam = () => {
  const { userId } = useContext(StateContext);
  const [users, setUsers] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [directCount, setDirectCount] = useState(0);
  const [teamMembers, setTeamMembers] = useState([]);
  const [currentView, setCurrentView] = useState("main");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);

  // Fetch users data when component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          " https://backend.shnsm1.com/api/users/data/all"
        );
        const allUsers = response.data;
        setUsers(allUsers);

        // Get the logged-in user's details
        const loggedUser = allUsers.find((user) => user.userId === userId);
        setLoggedInUser(loggedUser);

        // Calculate the direct count for the logged-in user
        const directReferrals = allUsers.filter(
          (user) => user.referralId === userId
        );
        setDirectCount(directReferrals.length);

        // Get all levels under the logged-in user
        const allLevels = getAllLevels(userId, allUsers);
        setTeamMembers(allLevels);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [userId]);

  // Watch for changes in teamMembers and log the updated state
  useEffect(() => {
    console.log("Updated Team Members:", teamMembers);
  }, [teamMembers]);

  // Recursive function to get all users at all levels under a specific user
  const getAllLevels = (referralId, allUsers) => {
    const currentLevelUsers = allUsers.filter(
      (user) => user.referralId === referralId
    );
    let allLevels = [...currentLevelUsers];

    currentLevelUsers.forEach((user) => {
      const lowerLevels = getAllLevels(user.userId, allUsers);
      allLevels = [...allLevels, ...lowerLevels];
    });

    return allLevels;
  };

  // Function to format date to DD-MM-YYYY
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  // Function to handle 'Downline' view for a particular user
  const handleDownlineView = (selectedUserId) => {
    const allLevelsUnderUser = getAllLevels(selectedUserId, users);
    setTeamMembers(allLevelsUnderUser);
    setCurrentView("downline");
    setSelectedUserId(selectedUserId);
  };

  // Function to handle 'Tree View' for a particular user
  const handleTreeView = (selectedUserId) => {
    const userHierarchy = getAllLevels(selectedUserId, users);
    setFilteredUsers(userHierarchy);
    setSelectedUserId(selectedUserId);
    setCurrentView("tree");
  };

  // Function to export the current Owner, Statistics, Action table to Excel
  const exportToExcel = () => {
    const data = teamMembers.map((user, index) => ({
      "Sr. No.": index + 1,
      "Login ID": user.userId || "N/A",
      Name: user.userName || "N/A",
      "Phone No.": user.phone || "N/A",
      "S. ID": user.referralId || "N/A",
      "S. Name": user.referralName || "N/A",
      DOJ: formatDate(user.dateOfJoining), // Format the date
      Package: user.package || "N/A",
      Status: user.blocked || "N/A",
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "MyTeamData");
    XLSX.writeFile(workbook, "MyTeamData.xlsx");
  };

  // Function to reset to main view and show the full team list
  const handleGoToMainMenu = () => {
    const allLevels = getAllLevels(userId, users);
    setTeamMembers(allLevels);
    setCurrentView("main");
    setSelectedUserId(null);
  };

  // Conditional rendering logic for Tree View
  if (currentView === "tree") {
    return (
      <div>
        <h2>Tree View for User ID: {selectedUserId}</h2>
        <LevelTree users={filteredUsers} userId={selectedUserId} />
        &nbsp;&nbsp;&nbsp;
        <button onClick={() => setCurrentView("main")}>
          Back to Main View
        </button>
      </div>
    );
  }

  // Main rendering logic
  return (
    <div className="my-team-container">
      <header className="my-team-header">
        <h2>My Team</h2>
      </header>

      {/* Show Downline View heading if the current view is 'downline' */}
      {currentView === "downline" && selectedUserId && (
        <h3>Downline View of User ID: {selectedUserId}</h3>
      )}

      {/* First Card: Logged-in User Details */}
      {currentView === "main" && loggedInUser && (
        <div className="my-team-card">
          <div className="my-team-card-header">
            <h3>{loggedInUser.userName || "N/A"}</h3>
            <p>USER ID: {loggedInUser.userId || "N/A"}</p>
          </div>
          <div className="my-team-card-body">
            <p>DOJ: {formatDate(loggedInUser.dateOfJoining)}</p>
            <p>
              Status:{" "}
              <span
                className={
                  loggedInUser.blocked === "true"
                    ? "status-red"
                    : "status-green"
                }
              >
                {loggedInUser.blocked ? "Inactive" : "Active"}
              </span>
            </p>
            <p>Direct Count: {directCount}</p>
          </div>
        </div>
      )}

      {/* Filter and Export options - only shown in main view */}
      {currentView === "main" && (
        <div className="filter-container">
          <div className="filter-options">
            <button className="excel-button" onClick={exportToExcel}>
              Excel Export
            </button>
          </div>
        </div>
      )}

      {/* Team Members Cards */}
      <div className="my-team-details-container">
        {teamMembers.map((user, index) => (
          <div key={index} className="my-team-card">
            <div className="my-team-card-header">
              <h3>{user.userName || "N/A"}</h3>
              <p>Login ID: {user.userId || "N/A"}</p>
            </div>
            <div className="my-team-card-body">
              <p>DOJ: {formatDate(user.dateOfJoining)}</p>
              <p>
                Status:{" "}
                <span
                  className={
                    user.blocked === "true" ? "status-red" : "status-green"
                  }
                >
                  {user.blocked ? "Inactive" : "Active"}
                </span>
              </p>
            </div>
            <div className="my-team-card-footer">
              <button
                className="my-team-action-button"
                onClick={() => handleDownlineView(user.userId)}
              >
                Downline
              </button>
              <button
                className="my-team-action-button"
                onClick={() => handleTreeView(user.userId)}
              >
                Tree View
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Go to Main Menu button for Downline View */}
      {currentView === "downline" && (
        <div className="go-to-main-menu-container">
          <button onClick={handleGoToMainMenu}>Go to Main Menu</button>
        </div>
      )}
    </div>
  );
};

export default MyTeam;
