import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import "./FundRequest.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faHome, faTimes } from "@fortawesome/free-solid-svg-icons";
import { StateContext } from "../../../contexts/ContextProvider";

const FundRequest = () => {
  const { userId, userName } = useContext(StateContext);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    amount: "",
    paymentMethod: "bankTransfer",
    description: "",
    file: null,
  });
  const [fundRequests, setFundRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [filterStatus, setFilterStatus] = useState(""); // Store selected filter
  const [searchText, setSearchText] = useState(""); // Store search text

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleFilterChange = (e) => {
    const status = e.target.value;
    setFilterStatus(status);
    applyFilters(status, searchText);
  };

  const handleSearchChange = (e) => {
    const text = e.target.value.toLowerCase();
    setSearchText(text);
    applyFilters(filterStatus, text);
  };

  const applyFilters = (status, search) => {
    let filtered = fundRequests;

    // Apply status filter
    if (status) {
      filtered = filtered.filter(
        (request) => request.status.toLowerCase() === status
      );
    }

    // Apply search filter
    if (search) {
      filtered = filtered.filter(
        (request) =>
          request.userName.toLowerCase().includes(search) ||
          request.description.toLowerCase().includes(search) ||
          request.amount.toString().includes(search)
      );
    }

    setFilteredRequests(filtered);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("amount", formData.amount);
    formDataObj.append("paymentMethod", formData.paymentMethod);
    formDataObj.append("description", formData.description);
    formDataObj.append("file", formData.file);
    formDataObj.append("userId", userId);
    formDataObj.append("userName", userName);

    try {
      const response = await axios.post(
        " https://backend.shnsm1.com/api/fund-request",
        formDataObj,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.status === 201) {
        alert("Fund request submitted successfully!");
        closeModal();
        fetchFundRequests();
      } else {
        alert("Failed to submit fund request");
      }
    } catch (error) {
      alert(
        `Failed to submit fund request: ${
          error.response?.data?.message || "An error occurred"
        }`
      );
    }
  };

  const fetchFundRequests = async () => {
    try {
      const response = await axios.get(
        ` https://backend.shnsm1.com/api/fund-request/fund-requests/${userId}`
      );
      setFundRequests(response.data);
      setFilteredRequests(response.data); // Initialize filtered requests
    } catch (error) {
      console.error("Error fetching fund requests:", error.message);
    }
  };

  useEffect(() => {
    fetchFundRequests();
  }, [userId]);

  return (
    <div className="fund-request-page">
      <div className="header">
        <div className="breadcrumb">
          <h2 style={{ color: "#000000" }}>Fund Request</h2>
          <div className="icon-with-text">
            <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
            <p>/ Wallet</p>
          </div>
        </div>
      </div>

      <div className="table-section">
        <div className="table-controls">
          <button className="new-request-btn" onClick={openModal}>
            <FontAwesomeIcon icon={faPlus} /> New Request
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div className="search-filter">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="search"
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange} // Add search handler
            />
            <select
              className="filter-dropdown"
              value={filterStatus}
              onChange={handleFilterChange}
            >
              <option value="">-- Pre Define Filter --</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
              <option value="pending">Pending</option>
            </select>
          </div>
        </div>

        <div className="request-cards">
          {filteredRequests.length > 0 ? (
            filteredRequests.map((request, index) => (
              <div className="request-card" key={request._id}>
                <div className="request-card-header">Request {index + 1}</div>
                <div className="request-card-body">
                  <p>
                    <strong>Username:</strong> {request.userName}
                  </p>
                  <p>
                    <strong>Amount:</strong> {request.amount}
                  </p>
                  <p>
                    <strong>Payment Mode:</strong> {request.paymentMethod}
                  </p>
                  <p>
                    <strong>Description:</strong> {request.description}
                  </p>
                  <p>
                    <strong>Status:</strong> {request.status}
                  </p>
                  {request.filePath && (
                    <a
                      href={` https://backend.shnsm1.com/${request.filePath}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Attachment
                    </a>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p>No fund requests available</p>
          )}
        </div>
      </div>

      {showModal && (
        <div className="modal1">
          <div className="modal-content">
            <div className="modal-header">
              <h3>Fund Request</h3>
              <button className="close-btn" onClick={closeModal}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="modal-body">
              <form className="fund-request-form" onSubmit={handleSubmit}>
                <label htmlFor="amount">Amount:</label>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                  placeholder="Enter amount"
                  required
                />
                <br></br>&nbsp;<br></br>
                <label htmlFor="paymentMethod">
                  Payment Method:&nbsp;&nbsp;&nbsp;&nbsp;
                </label>
                <select
                  id="paymentMethod"
                  name="paymentMethod"
                  value={formData.paymentMethod}
                  onChange={handleInputChange}
                >
                  <option value="bankTransfer">Bank Transfer</option>
                  <option value="paypal">Paypal</option>
                  <option value="creditCard">Credit Card</option>
                </select>
                <br></br>&nbsp;<br></br>
                <label htmlFor="description">Description:</label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Enter description"
                ></textarea>
                <br></br>
                <br></br>
                <div className="modal-footer">
                  <button type="submit" className="submit-btn">
                    Submit
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    className="close-btn"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FundRequest;
