import React, { useEffect, useState } from "react";
import { FaUsers, FaMoneyBillWave, FaHandHoldingUsd } from "react-icons/fa";
import "./AdminDashboard.css";
import axios from "axios";

const AdminDashboard = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [totalInactiveUsers, setTotalInactiveUsers] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCollectionWithTax, setTotalCollectionWithTax] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const response = await axios.get(
          " https://backend.shnsm1.com/api/users/total-users"
        );
        if (response.status === 200) {
          setTotalUsers(response.data.totalCount);
        } else {
          setError("Failed to fetch total users");
        }
      } catch (error) {
        console.error("Error fetching total users:", error);
        setError("Error fetching total users. Please try again later.");
      }
    };

    const fetchTotalActiveUsers = async () => {
      try {
        const response = await axios.get(
          " https://backend.shnsm1.com/api/users/api/users/active-users"
        );
        if (response.status === 200) {
          setTotalActiveUsers(response.data.totalActiveUsers);
        } else {
          setError("Failed to fetch active users: Unexpected response status");
        }
      } catch (error) {
        console.error("Error fetching active users:", error);
        setError(`Error fetching active users: ${error.message}`);
      }
    };

    const fetchTotalInactiveUsers = async () => {
      try {
        const response = await axios.get(
          " https://backend.shnsm1.com/api/users/api/users/inactive-users"
        );
        if (response.status === 200) {
          setTotalInactiveUsers(response.data.totalInactiveUsers);
        } else {
          setError(
            "Failed to fetch inactive users: Unexpected response status"
          );
        }
      } catch (error) {
        console.error("Error fetching inactive users:", error);
        setError(`Error fetching inactive users: ${error.message}`);
      }
    };

    const fetchFundTransfers = async () => {
      try {
        // Fetch creditAmount from the fund-transfers endpoint
        const fundTransfersResponse = await axios.get(
          " https://backend.shnsm1.com/api/fund-transfers"
        );
        const fundTransfersData = fundTransfersResponse.data;

        const creditAmount = fundTransfersData
          .filter((transfer) => transfer.method === "credit")
          .reduce((acc, transfer) => acc + transfer.amount, 0);
        setTotalCredit(creditAmount);

        // Fetch commission amounts from the transactions endpoint
        const transactionsResponse = await axios.get(
          " https://backend.shnsm1.com/api/transactions"
        );
        const transactionsData = transactionsResponse.data;
        const commissionAmount = transactionsData
          .filter((transaction) => transaction.method === "credit")
          .reduce((acc, transaction) => acc + transaction.amount, 0);

        console.log("Commision Amount::" + commissionAmount);

        const collectionWithTax = creditAmount - commissionAmount;
        setTotalCollectionWithTax(collectionWithTax);

        // Calculate total debit if necessary
        const totalDebitAmount = fundTransfersData
          .filter((transfer) => transfer.method === "debit")
          .reduce((acc, transfer) => acc + transfer.amount, 0);
        setTotalDebit(totalDebitAmount);
      } catch (error) {
        console.error("Error fetching fund transfers or transactions:", error);
        setError("Error fetching data. Please try again later.");
      }
    };

    fetchTotalUsers();
    fetchTotalActiveUsers();
    fetchTotalInactiveUsers();
    fetchFundTransfers();
  }, []);

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <h1>Admin Dashboard</h1>
        <p>
          <FaUsers /> Admin
        </p>
      </div>

      <div className="dashboard-grid">
        <div className="card">
          <div className="card-header">
            <FaUsers className="icon" />
            <p className="number">{totalUsers}</p>
            <h2>Total Users</h2>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <FaUsers className="icon" />
            <p className="number">{totalActiveUsers}</p>
            <h2>Active Users</h2>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <FaUsers className="icon" />
            <p className="number">{totalInactiveUsers}</p>
            <h2>Inactive Users</h2>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <FaMoneyBillWave className="icon" />
            <p className="number">{totalCredit.toFixed(2)}</p>
            <h2>Total Collection without Commission</h2>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <FaMoneyBillWave className="icon" />
            <p className="number">{totalCollectionWithTax.toFixed(2)}</p>
            <h2>Total Collection with Commission</h2>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <FaHandHoldingUsd className="icon" />
            <p className="number">{totalDebit.toFixed(2)}</p>
            <h2>Total Withdrawal</h2>
          </div>
        </div>

        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};

export default AdminDashboard;
