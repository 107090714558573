import React, { useState } from 'react';
import './Shop.css'; // Import the CSS file
import data_product1 from '../../../Assets/data1'; // Categories data
import all_product from '../../../Assets/all_product'; // Products data

function Shop() {
  const [selectedCategory, setSelectedCategory] = useState(data_product1[0] || null);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="app-container">
      <CategoryList
        categories={data_product1}
        onCategoryClick={handleCategoryClick}
      />
      <ProductList products={all_product.filter(product => product.category === selectedCategory?.name)} category={selectedCategory} />
    </div>
  );
}

export function CategoryList({ categories, onCategoryClick }) {
  return (
    <div className="category-list">
      <h3>Categories</h3>
      <ul>
        {categories.map((category) => (
          <li
            key={category.id}
            onClick={() => onCategoryClick(category)}
            onMouseEnter={(e) => handleMouseEnter(e, category.color, category.hoverTextColor)}
            style={{ borderLeft: `4px solid ${category.color}` }}
            className="category-item"
          >
            {category.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

function handleMouseEnter(event, bgColor, textColor) {
  event.target.style.setProperty('--hover-bg-color', bgColor);
  event.target.style.setProperty('--hover-text-color', textColor);
}

export function ProductList({ products, category }) {
  return (
    <div className="product-list">
      {category ? <h3>{category.name} Products</h3> : <h3>Select a Category</h3>}
      <ul>
        {products.length > 0 ? (
          products.map((product) => (
            <li key={product.id}>
              <div>{product.name}</div>
            </li>
          ))
        ) : (
          <p>No products available for this category.</p>
        )}
      </ul>
    </div>
  );
}

export default Shop;
