import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import "./Home.css"; // Import the CSS file
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate(); // useNavigate hook should be called at the top level

  const handleClick = () => {
    navigate('/login');
  };

  return (
    <div className="home">
      <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} interval={3000} showStatus={false}>
        {/* Slide 1 */}
        <div className="container">
          <div className="textSection">
            <h1 className="heading">SHNSM - I</h1>
            <p className="paragraph">
              At Samrat Home Needs Pvt Limited, our story is one of craftsmanship, creativity, and a deep commitment to transforming living spaces into works of art. Founded with a vision to redefine home needs.
            </p>
            <div className="buttonContainer">
              <button className="loginButton" onClick={handleClick}>Login</button>
              <button className="joinButton" onClick={handleClick}>SignUp</button>
            </div>
          </div>
          <div className="imageSection">
            <img src="/Home/Shnsm1.png" alt="Illustration 1" className="image" />
          </div>
        </div>

        {/* Slide 2 */}
        <div className="container">
          <div className="textSection">
            <h1 className="heading">SAMRAT HOME NEEDS SUPER MARKET</h1>
            <p className="paragraph">
              "SUPER MARKET" generally refer to refreshing, chilled beverages enjoyed especially in warm weather or as a means to quench thirst. The term can encompass a wide range of beverages, including sodas, juices, iced teas, and more.
            </p>
            <div className="buttonContainer">
              <button className="loginButton" onClick={handleClick}>Login</button>
              <button className="joinButton" onClick={handleClick}>SignUp</button>
            </div>
          </div>
          <div className="imageSection">
            <img src="/Home/slide2.png" alt="Illustration 2" className="image" />
          </div>
        </div>

        {/* Slide 3 */}
        <div className="container">
          <div className="textSection">
            <h1 className="heading">SPICES</h1>
            <p className="paragraph">
              Spices are aromatic and flavorful plant-derived substances used to enhance the taste, color, and aroma of food. They have been essential in culinary traditions worldwide and are integral to both everyday cooking and gourmet cuisine.
            </p>
            <div className="buttonContainer">
              <button className="loginButton" onClick={handleClick}>Login</button>
              <button className="joinButton" onClick={handleClick}>SignUp</button>
            </div>
          </div>
          <div className="imageSection">
            <img src="/Home/spices.png" alt="Illustration 3" className="image" />
          </div>
        </div>

        {/* Slide 4 */}
        <div className="container">
          <div className="textSection">
            <h1 className="heading">COOL DRINKS & BEVERAGES</h1>
            <p className="paragraph">
              Organic vegetables are grown using environmentally friendly farming practices that prioritize the health of the soil, ecosystems, and people. These practices typically avoid the use of synthetic pesticides, herbicides, fertilizers, and genetically modified organisms (GMOs).
            </p>
            <div className="buttonContainer">
              <button className="loginButton" onClick={handleClick}>Login</button>
              <button className="joinButton" onClick={handleClick}>SignUp</button>
            </div>
          </div>
          <div className="imageSection">
            <img src="/Home/cooldrinks.png" alt="Illustration 4" className="image" />
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Home;
