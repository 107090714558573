import React, { useState, useEffect } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axios from "axios";
import "./ChangePassword.css";

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState("");

  // Get user ID from local storage when component mounts
  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      setMessage("User not found. Please log in again.");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that the new password does not match the current password
    if (newPassword === currentPassword) {
      setMessage("New Password cannot be the same as the Current Password");
      return;
    }

    // Validate that new password matches confirm password
    if (newPassword !== confirmPassword) {
      setMessage("New Password and Confirm Password do not match");
      return;
    }

    try {
      // Make the API request to change the password
      const response = await axios.post(
        " https://backend.shnsm1.com/api/users/change-password",
        {
          userId,
          currentPassword,
          newPassword,
          confirmPassword,
        }
      );

      setMessage(response.data.message);
      // Clear the form fields after a successful password change
      if (response.status === 200) {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      // Handle error responses
      setMessage(error.response?.data?.message || "Server error");
    }
  };

  return (
    <form className="change-password-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          type={showCurrentPassword ? "text" : "password"}
          placeholder="Current Password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          className="input-field"
          required
        />
        <span
          className="visibility-toggle"
          onClick={() => setShowCurrentPassword(!showCurrentPassword)}
        >
          {showCurrentPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
        </span>
      </div>

      <div className="form-group">
        <input
          type={showNewPassword ? "text" : "password"}
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="input-field"
          required
        />
        <span
          className="visibility-toggle"
          onClick={() => setShowNewPassword(!showNewPassword)}
        >
          {showNewPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
        </span>
      </div>

      <div className="form-group">
        <input
          type={showConfirmPassword ? "text" : "password"}
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="input-field"
          required
        />
        <span
          className="visibility-toggle"
          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
        >
          {showConfirmPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
        </span>
      </div>

      <button type="submit" className="submit-button">
        Submit
      </button>

      {message && <p className="message">{message}</p>}
    </form>
  );
}

export default ChangePassword;
