let all_product = [
  { id: 1, name: "కందిపప్పు", category: "పప్పులు", price: 50.0 },
  { id: 2, name: "పెసరపప్పు", category: "పప్పులు", price: 85.0 },
  { id: 3, name: "పొట్టు పెసర పప్పు", category: "పప్పులు", price: 60.0 },
  { id: 4, name: "మినపప్పు", category: "పప్పులు", price: 100.0 },
  { id: 5, name: "మినపగుండ్లు", category: "పప్పులు", price: 85.0 },
  { id: 6, name: "D.H మినపగుండ్లు", category: "పప్పులు", price: 85.0 },
  { id: 7, name: "ఎర్రపప్పు", category: "పప్పులు", price: 85.0 },
  { id: 8, name: "శనగపప్పు", category: "పప్పులు", price: 85.0 },
  { id: 9, name: "బొబ్పెర్లపప్పు", category: "పప్పులు", price: 85.0 },
  { id: 10, name: "పల్లీలు", category: "పప్పులు", price: 85.0 },
  { id: 11, name: "టాటా ఆశీర్వాద్", category: "సాల్ట్స్", price: 85.0 },
  { id: 12, name: "అన్నపూర్ణ", category: "సాల్ట్స్", price: 85.0 },
  { id: 13, name: "రాక్", category: "సాల్ట్స్", price: 85.0 },
  { id: 14, name: "కల్లుప్పు", category: "సాల్ట్స్", price: 85.0 },
  { id: 15, name: "ట్రిపుల్ ఎక్స్", category: "డిటర్జంట్ సోప్స్", price: 85.0 },
  { id: 16, name: "ఈటా", category: "డిటర్జంట్ సోప్స్", price: 85.0 },
  { id: 17, name: "5", category: "డిటర్జంట్ సోప్స్", price: 85.0 },
  { id: 18, name: "రిన్", category: "డిటర్జంట్ సోప్స్", price: 85.0 },
  { id: 19, name: "సర్ఫక్సల్", category: "డిటర్జంట్ సోప్స్", price: 85.0 },
  { id: 20, name: "ఏరియల్", category: "డిటర్జంట్ సోప్స్", price: 85.0 },
  { id: 21, name: "వీల్", category: "డిటర్జంట్ సోప్స్", price: 85.0 },
  { id: 22, name: "హేన్కో", category: "డిటర్జంట్ సోప్స్", price: 85.0 },
  { id: 23, name: "ఊర్వశి", category: "డిటర్జంట్ సోప్స్", price: 85.0 },
  { id: 24, name: "విమ్", category: "డిష్వాష్ లిక్విడ్స్", price: 85.0 },
  { id: 25, name: "గిఫీ", category: "డిష్వాష్ లిక్విడ్స్", price: 85.0 },
  { id: 26, name: "ఏక్స్", category: "డిష్వాష్ లిక్విడ్స్", price: 85.0 },
  { id: 27, name: "ఏక్సో", category: "డిష్వాష్ లిక్విడ్స్", price: 85.0 },
  { id: 28, name: "ప్రిల్", category: "డిష్వాష్ లిక్విడ్స్", price: 85.0 },
  { id: 29, name: "పవర్ వాష్", category: "డిష్వాష్ లిక్విడ్స్", price: 85.0 },
  { id: 30, name: "డైరి మిల్క్", category: "చాక్లెట్స్", price: 85.0 },
  { id: 31, name: "ఫై స్టార్", category: "చాక్లెట్స్", price: 85.0 },
  { id: 32, name: "పర్స్", category: "చాక్లెట్స్", price: 85.0 },
  { id: 33, name: "మంచు", category: "చాక్లెట్స్", price: 85.0 },
  { id: 34, name: "క్విడ్స్", category: "చాక్లెట్స్", price: 85.0 },
  { id: 35, name: "కిట్ కాట్", category: "చాక్లెట్స్", price: 85.0 },
  { id: 36, name: "ట్నిక్కర్", category: "చాక్లెట్స్", price: 85.0 },
  { id: 37, name: "జాన్సన్ బేబి", category: "బాత్ సోప్స్", price: 85.0 },
  { id: 38, name: "బేబి సంతూర్", category: "బాత్ సోప్స్", price: 85.0 },
  { id: 39, name: "పియర్స్", category: "బాత్ సోప్స్", price: 85.0 },
  { id: 40, name: "లైఫ్బాయ్", category: "బాత్ సోప్స్", price: 85.0 },
  { id: 41, name: "మేడిమిక్స్", category: "బాత్ సోప్స్", price: 85.0 },
  { id: 42, name: "డేటాల్", category: "బాత్ సోప్స్", price: 85.0 },
  { id: 43, name: "మైసూర్ సాండిల్", category: "బాత్ సోప్స్", price: 85.0 },
  { id: 44, name: "సంతూర్", category: "బాత్ సోప్స్", price: 85.0 },
  { id: 45, name: "అంబిక అగర్బత్తీలు", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 46, name: "సైకిల్", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 47, name: "మంగళ్ దీప్", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 48, name: "మొగల్ గార్డెన్", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 49, name: "జెడ్ బ్లాక్", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 50, name: "దూప్ స్టిక్స్", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 51, name: "దూప్ కప్స్", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 52, name: "కర్పూరం", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 53, name: "ఓత్తులు", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 54, name: "గంధంపౌడర్", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 55, name: "కుంకుమ", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 56, name: "సాబ్రాని", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 57, name: "పీతాంబరి", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 58, name: "ఆవునెయ్యి", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 59, name: "అగ్గి పెట్టెలు", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 60, name: "నువ్వుల నూనె", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 61, name: "వీల్", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 62, name: "పసుపు", category: "పూజ సామాగ్రి", price: 85.0 },
  { id: 63, name: "రిన్", category: "డిటర్జెంట్ పౌడర్స్", price: 85.0 },
  { id: 64, name: "ఈటా", category: "డిటర్జెంట్ పౌడర్స్", price: 85.0 },
  { id: 65, name: "వీల్", category: "డిటర్జెంట్ పౌడర్స్", price: 85.0 },
  { id: 66, name: "ఏరియల్", category: "డిటర్జెంట్ పౌడర్స్", price: 85.0 },
  { id: 67, name: "సర్ఎక్సల్", category: "డిటర్జెంట్ పౌడర్స్", price: 85.0 },
  { id: 68, name: "టైడ్", category: "డిటర్జెంట్ పౌడర్స్", price: 85.0 },
  { id: 69, name: "హేన్కో", category: "డిటర్జెంట్ పౌడర్స్", price: 85.0 },
  { id: 70, name: "పినాయిల్", category: "ఫ్లోర్ క్లీనర్", price: 85.0 },
  { id: 71, name: "యాసిడ్", category: "ఫ్లోర్ క్లీనర్", price: 85.0 },
  { id: 72, name: "బ్లీచింగ్ పౌడర్", category: "ఫ్లోర్ క్లీనర్", price: 85.0 },
  { id: 73, name: "డేటాల్", category: "ఫ్లోర్ క్లీనర్", price: 85.0 },
  { id: 74, name: "లైజాల్", category: "ఫ్లోర్ క్లీనర్", price: 85.0 },
  { id: 75, name: "కోలిన్", category: "ఫ్లోర్ క్లీనర్", price: 85.0 },
  { id: 76, name: "డో మెక్స్", category: "ఫ్లోర్ క్లీనర్", price: 85.0 },
  { id: 77, name: "హర్పిక్", category: "ఫ్లోర్ క్లీనర్", price: 85.0 },
  { id: 78, name: "అల్ట్రా", category: "ఫ్లోర్ క్లీనర్", price: 85.0 },
  { id: 79, name: "లేస్", category: "చిప్స్", price: 85.0 },
  { id: 80, name: "బింగో", category: "చిప్స్", price: 85.0 },
  { id: 81, name: "హల్కెపల్కె", category: "చిప్స్", price: 85.0 },
  { id: 82, name: "హల్దిరామ్ ప్రోడక్స్", category: "చిప్స్", price: 85.0 },
  { id: 83, name: "కురురె", category: "చిప్స్", price: 85.0 },
  { id: 84, name: "విస్పర్", category: "సానిటరి ప్యాడ్స్", price: 85.0 },
  { id: 85, name: "స్టేఫ్రీ", category: "సానిటరి ప్యాడ్స్", price: 85.0 },
  { id: 86, name: "సోఫీయా", category: "సానిటరి ప్యాడ్స్", price: 85.0 },
  { id: 87, name: "ప్యాంపర్స్", category: "సానిటరి ప్యాడ్స్", price: 85.0 },
  { id: 88, name: "మామి కో", category: "సానిటరి ప్యాడ్స్", price: 85.0 },
  { id: 89, name: "గుడ్ డే", category: "బిస్కెట్స్", price: 85.0 },
  { id: 90, name: "ఫిఫ్టీ ఫిఫ్టీ", category: "బిస్కెట్స్", price: 85.0 },
  { id: 91, name: "న్యూట్రిన్", category: "బిస్కెట్స్", price: 85.0 },
  { id: 92, name: "బౌన్స్", category: "బిస్కెట్స్", price: 85.0 },
  { id: 93, name: "డార్క్ ఫాంటస్", category: "బిస్కెట్స్", price: 85.0 },
  { id: 94, name: "పార్లేజీ", category: "బిస్కెట్స్", price: 85.0 },
  { id: 95, name: "మిల్కీబికీస్", category: "బిస్కెట్స్", price: 85.0 },
  { id: 96, name: "బోంబాయ్ రవ్య", category: "రవ్వలు", price: 85.0 },
  { id: 97, name: "ఇడ్లీ రవ్య", category: "రవ్వలు", price: 85.0 },
  { id: 98, name: "గోధుమ రవ్య", category: "రవ్వలు", price: 85.0 },
  { id: 99, name: "జోన్న రవ్య", category: "రవ్వలు", price: 85.0 },
  { id: 100, name: "మల్టీగ్రేడ్ రవ్య", category: "రవ్వలు", price: 85.0 },
  { id: 101, name: "మ్యూస్", category: "న్యూడిల్స్", price: 85.0 },
  { id: 102, name: "ఇప్పి", category: "న్యూడిల్స్", price: 85.0 },
  { id: 103, name: "కొర్రలు", category: "చిరు ధాన్యాలు", price: 85.0 },
  { id: 104, name: "అండ్ కొర్రలు", category: "చిరు ధాన్యాలు", price: 85.0 },
  { id: 105, name: "సామలు", category: "చిరు ధాన్యాలు", price: 85.0 },
  { id: 106, name: "అరికెలు", category: "చిరు ధాన్యాలు", price: 85.0 },
  { id: 107, name: "సోయాబీన్స్", category: "చిరు ధాన్యాలు", price: 85.0 },
  { id: 108, name: "రాగులు", category: "చిరు ధాన్యాలు", price: 85.0 },
  { id: 109, name: "జొన్నలు", category: "చిరు ధాన్యాలు", price: 85.0 },
  { id: 110, name: "పెసర్లు", category: "చిరు ధాన్యాలు", price: 85.0 },
  { id: 111, name: "శనగలు", category: "చిరు ధాన్యాలు", price: 85.0 },
  { id: 112, name: "బొబ్బెర్లు", category: "చిరు ధాన్యాలు", price: 85.0 },
  { id: 113, name: "ఊదలు", category: "చిరు ధాన్యాలు", price: 85.0 },
  { id: 114, name: "అవిసగింజలు", category: "చిరు ధాన్యాలు", price: 85.0 },
  { id: 115, name: "గోధుమ పిండి ఆశీర్వాద్", category: "పిండీలు", price: 85.0 },
  { id: 116, name: "గోధుమ పిండి మల్టిగ్రేడ్", category: "పిండీలు", price: 85.0 },
  { id: 117, name: "చీక్ ఫ్రేష్ ఆటా", category: "పిండీలు", price: 85.0 },
  { id: 118, name: "మైదా పిండి", category: "పిండీలు", price: 85.0 },
  { id: 119, name: "శనగ పిండి", category: "పిండీలు", price: 85.0 },
  { id: 120, name: "పూరి పిండి", category: "పిండీలు", price: 85.0 },
  { id: 121, name: "గాగి పిండి", category: "పిండీలు", price: 85.0 },
  { id: 122, name: "జోన్న పిండి", category: "పిండీలు", price: 85.0 },
  { id: 123, name: "రైస్ బ్రాండ్", category: "ఆయిల్స్", price: 85.0 },
  { id: 124, name: "విజయ", category: "ఆయిల్స్", price: 85.0 },
  { id: 125, name: "విజయ పల్లీ", category: "ఆయిల్స్", price: 85.0 },
  { id: 126, name: "సన్ ఫ్లవర్", category: "ఆయిల్స్", price: 85.0 },
  { id: 127, name: "సన్పూరి", category: "ఆయిల్స్", price: 85.0 },
  { id: 128, name: "సన్రాప్", category: "ఆయిల్స్", price: 85.0 },
  { id: 129, name: "1 గోల్డ్ విన్నర్", category: "ఆయిల్స్", price: 85.0 },
  { id: 130, name: "ఫ్రార్చూన్", category: "ఆయిల్స్", price: 85.0 },
  { id: 131, name: "పతంజలి", category: "ఆయిల్స్", price: 85.0 },
  { id: 132, name: "ఫ్రీడమ్", category: "ఆయిల్స్", price: 85.0 },
  { id: 133, name: "కోల్గేట్", category: "టూత్ పేస్ట్", price: 85.0 },
  { id: 134, name: "క్లోజప్", category: "టూత్ పేస్ట్", price: 85.0 },
  { id: 135, name: "పెప్సిడెంట్ సాల్ట్", category: "టూత్ పేస్ట్", price: 85.0 },
  { id: 136, name: "డాబర్ రెడ్", category: "టూత్ పేస్ట్", price: 85.0 },
  { id: 137, name: "వీకో", category: "టూత్ పేస్ట్", price: 85.0 },
  { id: 138, name: "ఓరల్ బి", category: "టూత్ పేస్ట్", price: 85.0 },
  { id: 139, name: "డెంట్ కాంత్", category: "టూత్ పేస్ట్", price: 85.0 },
  { id: 140, name: "కోల్గెట్ మ్యాక్స్ ప్రెష్", category: "టూత్ పేస్ట్", price: 85.0 },
  { id: 141, name: "మిస్ వాక్", category: "టూత్ పేస్ట్", price: 85.0 },
  { id: 142, name: "వెద శక్తి", category: "టూత్ పేస్ట్", price: 85.0 },
  { id: 143, name: "స్పార్క్లింగ్ వైట్", category: "టూత్ పేస్ట్", price: 85.0 },
  { id: 144, name: "సెన్సోడైన్", category: "టూత్ పేస్ట్", price: 85.0 },
  { id: 145, name: "కాల్గెట్ జిగ్జాగ్", category: "టూత్ పేస్ట్", price: 85.0 },
  { id: 146, name: "స్పైట్", category: "కూల్ డ్రింక్స్", price: 85.0 },
  { id: 147, name: "మాజా", category: "కూల్ డ్రింక్స్", price: 85.0 },
  { id: 148, name: "తమ్స్ ప్", category: "కూల్ డ్రింక్స్", price: 85.0 },
  { id: 149, name: "యాపిల్", category: "కూల్ డ్రింక్స్", price: 85.0 },
  { id: 150, name: "ఓరల్ బి", category: "టూత్ బ్రెష్", price: 85.0 },
  { id: 151, name: "ఓరల్ బి సెన్స్టీవ్", category: "టూత్ బ్రెష్", price: 85.0 },
  { id: 152, name: "అజయ్", category: "టూత్ బ్రెష్", price: 85.0 },
  { id: 153, name: "సెన్స్ డైన్", category: "టూత్ బ్రెష్", price: 85.0 },
  { id: 154, name: "కార్గెట్", category: "టూత్ బ్రెష్", price: 85.0 },
  { id: 155, name: "సూపర్ ప్లేక్సి", category: "టూత్ బ్రెష్", price: 85.0 },
  { id: 156, name: "కార్గెట్ జిగ్ జాగ్", category: "టూత్ బ్రెష్", price: 85.0 },
  { id: 157, name: "కాల్గెట్ వెదశక్తి", category: "టూత్ బ్రెష్", price: 85.0 },
  { id: 158, name: "ట్రిపుల్ ఎక్స్", category: "డిటర్జెట్ లిక్విడ్స్", price: 85.0 },
  { id: 159, name: "ఈటా", category: "డిటర్జెట్ లిక్విడ్స్", price: 85.0 },
  { id: 160, name: "రిన్", category: "డిటర్జెట్ లిక్విడ్స్", price: 85.0 },
  { id: 161, name: "సర్ఫక్సల్", category: "డిటర్జెట్ లిక్విడ్స్", price: 85.0 },
  { id: 162, name: "ఏరియల్", category: "డిటర్జెట్ లిక్విడ్స్", price: 85.0 },
  { id: 163, name: "వీల్", category: "డిటర్జెట్ లిక్విడ్స్", price: 85.0 },
  { id: 164, name: "మీరా", category: "షాంపోస్", price: 85.0 },
  { id: 165, name: "హేడ్లైన్ షోల్డర్", category: "షాంపోస్", price: 85.0 },
  { id: 166, name: "చిక్", category: "షాంపోస్", price: 85.0 },
  { id: 167, name: "చిక్ ఎగ్ వైట్", category: "షాంపోస్", price: 85.0 },
  { id: 168, name: "కార్తీకా", category: "షాంపోస్", price: 85.0 },
  { id: 169, name: "క్లీనిక్ ప్లేస్", category: "షాంపోస్", price: 85.0 },
  { id: 170, name: "డవ్", category: "షాంపోస్", price: 85.0 },
  { id: 171, name: "సన్ సిల్క్", category: "షాంపోస్", price: 85.0 },
  { id: 172, name: "ప్యాంటిన్", category: "షాంపోస్", price: 85.0 },
  { id: 173, name: "బాదాం", category: "డ్రై ఫ్రూట్స్", price: 85.0 },
  { id: 174, name: "జీడిపప్పు", category: "డ్రై ఫ్రూట్స్", price: 85.0 },
  { id: 175, name: "కిస్ మిస్", category: "డ్రై ఫ్రూట్స్", price: 85.0 },
  { id: 176, name: "వాల్ నట్స్", category: "డ్రై ఫ్రూట్స్", price: 85.0 },
  { id: 177, name: "అంజీర్", category: "డ్రై ఫ్రూట్స్", price: 85.0 },
  { id: 178, name: "పిస్తా పప్పు", category: "డ్రై ఫ్రూట్స్", price: 85.0 },
  { id: 179, name: "పంప్కిన్ సీడ్స్", category: "డ్రై ఫ్రూట్స్", price: 85.0 },
  { id: 180, name: "వాటర్మీలాన్ సీడ్స్", category: "డ్రై ఫ్రూట్స్", price: 85.0 },
  { id: 181, name: "నల్ల ద్రాక్ష", category: "డ్రై ఫ్రూట్స్", price: 85.0 },
  { id: 182, name: "ఖర్జూర", category: "డ్రై ఫ్రూట్స్", price: 85.0 },
  { id: 183, name: "ఎండు ఖర్జూర", category: "డ్రై ఫ్రూట్స్", price: 85.0 },
  { id: 184, name: "పొద్దు తిరుగుడు సీడ్స్", category: "డ్రై ఫ్రూట్స్", price: 85.0 },
  { id: 185, name: "ఫూల్ మకాస సీడ్స్", category: "డ్రై ఫ్రూట్స్", price: 85.0 },
  { id: 186, name: "చీయా సీడ్స్", category: "డ్రై ఫ్రూట్స్", price: 85.0 },
  { id: 187, name: "ఫేరండ్ లవ్లీ", category: "ఫేస్ క్రీమ్స్", price: 85.0 },
  { id: 188, name: "ఫేరవర్", category: "ఫేస్ క్రీమ్స్", price: 85.0 },
  { id: 189, name: "క్లీన్డ్ క్లీయర్ ఫేష్ వాష్", category: "ఫేస్ క్రీమ్స్", price: 85.0 },
  { id: 190, name: "కోల్డ్ క్రీమ్స్", category: "ఫేస్ క్రీమ్స్", price: 85.0 },
  { id: 191, name: "పాండ్స్ పౌడర్", category: "ఫేస్ క్రీమ్స్", price: 85.0 },
  { id: 192, name: "స్పింజ్ పౌడర్", category: "ఫేస్ క్రీమ్స్", price: 85.0 },
  { id: 193, name: "సంతూర్ పౌడర్", category: "ఫేస్ క్రీమ్స్", price: 85.0 },
  { id: 194, name: "బేబి సంతూర్", category: "ఫేస్ క్రీమ్స్", price: 85.0 },
  { id: 195, name: "డాజ్లర్", category: "ఫేస్ క్రీమ్స్", price: 85.0 },
  { id: 196, name: "జాన్సన్ బేబి", category: "ఫేస్ క్రీమ్స్", price: 85.0 },
  { id: 197, name: "ఇండికా ఈజీ", category: "హేయిర్ డై", price: 85.0 },
  { id: 198, name: "బ్లాక్ రంగోలి", category: "హేయిర్ డై", price: 85.0 },
  { id: 199, name: "రెడ్ రంగోలి", category: "హేయిర్ డై", price: 85.0 },
  { id: 200, name: "బ్లాక్ రోజ్", category: "హేయిర్ డై", price: 85.0 },
  { id: 201, name: "గోద్రెజ్ రీచ్ క్రీమ్", category: "హేయిర్ డై", price: 85.0 },
  { id: 202, name: "గోద్రెజ్ పౌడర్", category: "హేయిర్ డై", price: 85.0 },
  { id: 203, name: "విఐపి కలర్", category: "హేయిర్ డై", price: 85.0 },
  { id: 204, name: "గార్నియర్", category: "హేయిర్ డై", price: 85.0 },
  { id: 205, name: "సెట్ వెట్", category: "హేయిర్ డై", price: 85.0 },
  { id: 206, name: "ఫ్యారాషూట్", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 207, name: "వాటిక", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 208, name: "అశ్విని", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 209, name: "సూపర్వాస్మల్", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 210, name: "ఆలోవెరా", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 211, name: "కేస్కింగ్", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 212, name: "ఇందులేఖా", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 213, name: "ఆలీవ్", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 214, name: "బజాజ్ ఆల్మండ్ డ్రాప్స్", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 215, name: "రెడ్ ఆనియన్", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 216, name: "వాస్మల్ జాస్మిన్", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 217, name: "డాబర్ ఆమ్ల", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 218, name: "ఫ్యారాషూట్ ఆలోవెరా", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 219, name: "హిమామి ఆలీవ్", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 220, name: "క్లీనిక్స్", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 221, name: "పతంజలి కేశ్కాంతి", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 222, name: "బజాజ్ బ్రామ్మ ఆమ్ల", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 223, name: "నవరత్న తైలం", category: "హేయిర్ ఆయిల్స్", price: 85.0 },
  { id: 224, name: "దనియాలు", category: "మసాలాలు", price: 85.0 },
  { id: 225, name: "జీలకర్ర", category: "మసాలాలు", price: 85.0 },
  { id: 226, name: "ಅవాలు", category: "మసాలాలు", price: 85.0 },
  { id: 227, name: "పోపుగింజలు", category: "మసాలాలు", price: 85.0 },
  { id: 228, name: "చికెన్ మసాల", category: "మసాలాలు", price: 85.0 },
  { id: 229, name: "మిరియాలు", category: "మసాలాలు", price: 85.0 },
  { id: 230, name: "మెంతులు", category: "మసాలాలు", price: 85.0 },
  { id: 231, name: "దాల్చినచక్క", category: "మసాలాలు", price: 85.0 },
  { id: 232, name: "ఆనాసపువ్వు", category: "మసాలాలు", price: 85.0 },
  { id: 233, name: "మరాఠి మొగ్గ", category: "మసాలాలు", price: 85.0 },
  { id: 234, name: "జాజీ కాయ", category: "మసాలాలు", price: 85.0 },
  { id: 235, name: "జా పత్రి", category: "మసాలాలు", price: 85.0 },
  { id: 236, name: "లంవంగాలు", category: "మసాలాలు", price: 85.0 },
  { id: 237, name: "యాలకులు", category: "మసాలాలు", price: 85.0 },
  { id: 238, name: "పసుపు", category: "మసాలాలు", price: 85.0 },
  { id: 239, name: "హింగువ", category: "మసాలాలు", price: 85.0 },
  { id: 240, name: "అల్లం, వెల్లుల్లి, ఉల్లిగడ్డలు", category: "మసాలాలు", price: 85.0 },
  { id: 241, name: "గసగసాలు", category: "మసాలాలు", price: 85.0 },
  { id: 242, name: "శొంటి", category: "మసాలాలు", price: 85.0 },
  { id: 243, name: "కుంకుమ పువ్వు", category: "మసాలాలు", price: 85.0 },
  { id: 244, name: "తెల్ల నువ్వులు", category: "మసాలాలు", price: 85.0 },
  { id: 245, name: "నల్ల నువ్వులు", category: "మసాలాలు", price: 85.0 },
  { id: 246, name: "ఫుడ్ కలర్", category: "మసాలాలు", price: 85.0 },
  { id: 247, name: "గరమ్ మసాల", category: "మసాలాలు", price: 85.0 },
  { id: 248, name: "కొబ్బరి పొడి", category: "మసాలాలు", price: 85.0 },
  { id: 249, name: "కొబ్బరి కుడకలు", category: "మసాలాలు", price: 85.0 },
  { id: 250, name: "బిరియాని మసాల", category: "మసాలాలు", price: 85.0 },
  { id: 251, name: "బిరియాని ఆకు", category: "మసాలాలు", price: 85.0 }
];

export default all_product;
