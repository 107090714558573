import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Checkbox,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Grid,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faWallet } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const Payout = ({ userId }) => {
  const [filterVisible, setFilterVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [bankDetails, setBankDetails] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(
          " https://backend.shnsm1.com/api/fund-transfers/"
        );
        const data = response.data;

        setTransactionData(data);
        setFilteredData(data);

        data.forEach((transaction) => {
          fetchBankDetails(transaction.userId);
        });
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    };

    const fetchBankDetails = async (userId) => {
      try {
        const response = await axios.get(
          ` https://backend.shnsm1.com/api/bank-details/${userId}`
        );
        setBankDetails((prevDetails) => ({
          ...prevDetails,
          [userId]: response.data,
        }));
      } catch (error) {
        console.error(
          `Error fetching bank details for userId: ${userId}`,
          error
        );
      }
    };

    fetchTransactions();
  }, [userId]);

  const handleFilterToggle = () => setFilterVisible(!filterVisible);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setStartDate("");
      setEndDate("");
    }
  };

  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);

  const handleSubmit = () => {
    if (startDate && endDate) {
      const filtered = transactionData.filter((transaction) => {
        const transactionDate = new Date(transaction.transactionDate);
        const start = new Date(startDate);
        const end = new Date(endDate);

        return transactionDate >= start && transactionDate <= end;
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(transactionData);
    }
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setIsChecked(false);
    setFilteredData(transactionData);
  };

  const calculateTdsAndSurcharge = (amount) => {
    if (amount > 100000) {
      return { tds: 10, surcharge: 10 };
    } else {
      return { tds: 5, surcharge: 10 };
    }
  };

  const calculateNetAmount = (amount, tds, surcharge) => {
    const tdsAmount = (amount * tds) / 100;
    const surchargeAmount = (amount * surcharge) / 100;
    return amount - tdsAmount - surchargeAmount;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value) {
      const searchedData = transactionData.filter((transaction) =>
        transaction.userId.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredData(searchedData);
    } else {
      setFilteredData(transactionData);
    }
  };

  return (
    <Box sx={{ p: 3, backgroundColor: "#f9f9f9", minHeight: "100%" }}>
      <Box sx={{ mb: 3, textAlign: "center", color: "#333" }}>
        <Typography variant="h4" component="h2">
          Pay Out
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
          <FontAwesomeIcon
            icon={faWallet}
            style={{ marginRight: 8, color: "#555" }}
          />
          <Typography>/ Pay Out</Typography>
        </Box>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <Button
              onClick={handleFilterToggle}
              variant="contained"
              style={{ backgroundColor: "#007bff", color: "#fff" }}
              startIcon={<FontAwesomeIcon icon={faFilter} />}
              fullWidth
            >
              Filter
            </Button>
          </Grid>
        </Grid>
      </Box>

      {filterVisible && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            backgroundColor: "#668a66",
            p: 2,
            borderRadius: 1,
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
            <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              disabled={!isChecked}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              disabled={!isChecked}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#28a745", color: "#fff" }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              style={{ color: "#6c757d", borderColor: "#6c757d" }}
              onClick={handleReset}
            >
              Reset
            </Button>
          </Box>
        </Box>
      )}

      <div style={{ marginBottom: "16px", marginTop: "16px" }}>
        <input
          style={{
            width: "100%",
            padding: "8px 12px",
            fontSize: "16px",
            borderRadius: "8px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            outline: "none",
            transition: "border-color 0.3s",
          }}
          placeholder="Search by User ID"
          value={searchQuery}
          onChange={handleSearch}
          onFocus={(e) => (e.target.style.borderColor = "#1976d2")}
          onBlur={(e) => (e.target.style.borderColor = "rgba(0, 0, 0, 0.23)")}
        />
      </div>

      <TableContainer component={Paper} sx={{ mt: 3, backgroundColor: "#fff" }}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#e9ecef" }}>
              <TableCell sx={{ color: "black" }}>Sr No.</TableCell>
              <TableCell sx={{ color: "black" }}>User ID</TableCell>
              <TableCell sx={{ color: "black" }}>Wallet</TableCell>
              <TableCell sx={{ color: "black" }}>Date</TableCell>
              <TableCell sx={{ color: "black" }}>Bank Details</TableCell>
              <TableCell sx={{ color: "black" }}>Type</TableCell>
              <TableCell sx={{ color: "black" }}>Amount</TableCell>
              <TableCell sx={{ color: "black" }}>Deduction</TableCell>
              <TableCell sx={{ color: "black" }}>Net Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .filter((row) => row.method.toLowerCase() === "debit")
              .map((row, index) => {
                const { tds, surcharge } = calculateTdsAndSurcharge(row.amount);
                const netAmount = calculateNetAmount(
                  row.amount,
                  tds,
                  surcharge
                );
                return (
                  <TableRow key={index} hover style={{ color: "#495057" }}>
                    <TableCell sx={{ color: "black" }}>{index + 1}</TableCell>
                    <TableCell sx={{ color: "black" }}>{row.userId}</TableCell>
                    <TableCell sx={{ color: "black" }}>E-Wallet</TableCell>
                    <TableCell sx={{ color: "black" }}>
                      {formatDate(row.transactionDate)}
                    </TableCell>
                    <TableCell sx={{ color: "black" }}>
                      {bankDetails[row.userId] ? (
                        <div>
                          <p>
                            <b>Bank Name:</b>{" "}
                            {bankDetails[row.userId]?.bankName || "N/A"}
                          </p>
                          <p>
                            <b>Account Holder:</b>{" "}
                            {bankDetails[row.userId]?.accountHolderName ||
                              "N/A"}
                          </p>
                          <p>
                            <b>Account No:</b>{" "}
                            {bankDetails[row.userId]?.accountNumber || "N/A"}
                          </p>
                          <p>
                            <b>Branch:</b>{" "}
                            {bankDetails[row.userId]?.branchName || "N/A"}
                          </p>
                          <p>
                            <b>IFSC:</b>{" "}
                            {bankDetails[row.userId]?.ifscCode || "N/A"}
                          </p>
                        </div>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell sx={{ color: "black" }}>{row.method}</TableCell>
                    <TableCell sx={{ color: "black" }}>
                      {row.amount} ₹
                    </TableCell>
                    <TableCell sx={{ color: "black" }}>
                      TDS: {tds}%<br />
                      Surcharge: {surcharge}%
                    </TableCell>
                    <TableCell sx={{ color: "black" }}>
                      {netAmount.toFixed(2)} ₹
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
        <Pagination count={10} variant="outlined" shape="rounded" />
      </Box>
    </Box>
  );
};

export default Payout;
