import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Grid,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet, faFileExport } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import axios from "axios";
import "./Withdrawals.css";

const Withdrawals = ({ userId }) => {
  const [filter, setFilter] = useState("");
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [withdrawalSum, setWithdrawalSum] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          ` https://backend.shnsm1.com/api/fund-transfers/user/${userId}`
        );
        const debitTransactions = response.data.filter(
          (transaction) => transaction.method === "debit"
        );

        const sum = debitTransactions.reduce(
          (acc, transaction) => acc + transaction.amount,
          0
        );
        setWithdrawalData(debitTransactions);
        setWithdrawalSum(sum);
      } catch (error) {
        console.error("Error fetching withdrawal data:", error);
      }
    };
    fetchData();
  }, [userId]);

  const calculateDeductions = (amount) => {
    // This function is only needed if withdrawalData does not include net amounts.
    let tdsPercentage = amount >= 100000 ? 10 : 5;
    let surchargePercentage = 10;
    let tds = (tdsPercentage / 100) * amount;
    let surcharge = (surchargePercentage / 100) * amount;
    let netAmount = amount - tds - surcharge;

    return { tdsPercentage, surchargePercentage, tds, surcharge, netAmount };
  };

  const exportToExcel = () => {
    const data = withdrawalData.map((row, index) => {
      // Check if the row already contains a net amount to avoid double deduction.
      const netAmount =
        row.netAmount || calculateDeductions(row.amount).netAmount;
      return {
        "Sr No.": index + 1,
        Wallet: row.wallet,
        Date: new Date(row.transactionDate).toLocaleDateString(),
        "Amount (Before Deductions)": row.amount.toFixed(2),
        "Net Amount (After Deductions)": netAmount.toFixed(2),
        Status: row.status,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Withdrawals");
    XLSX.writeFile(workbook, "Withdrawals.xlsx");
  };

  return (
    <Box sx={{ p: 3, backgroundColor: "white" }}>
      <Box sx={{ mb: 3, textAlign: "center" }}>
        <Typography variant="h4" component="h2">
          Withdrawals
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
          <FontAwesomeIcon
            icon={faWallet}
            style={{ marginRight: 8, color: "black" }}
          />
          <Typography sx={{ p: 3, color: "black" }}>/ Withdrawals</Typography>
        </Box>
      </Box>

      <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={12} md={3}>
          <Button
            onClick={exportToExcel}
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faFileExport} />}
            fullWidth
          >
            Excel Export
          </Button>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box display="flex" alignItems="center" gap={2} flexDirection="row">
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              fullWidth
            />
            <Select
              sx={{ color: "black", backgroundColor: "cornflowerblue" }}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="">-- Pre Define Filter --</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
            </Select>
          </Box>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "black" }}>Sr No.</TableCell>
              <TableCell sx={{ color: "black" }}>Wallet</TableCell>
              <TableCell sx={{ color: "black" }}>Date</TableCell>
              <TableCell sx={{ color: "black" }}>
                Amount (Before Deductions)
              </TableCell>
              <TableCell sx={{ color: "black" }}>
                Amount (After Deductions)
              </TableCell>{" "}
              {/* New column */}
            </TableRow>
          </TableHead>
          <TableBody>
            {withdrawalData.length > 0 ? (
              withdrawalData.map((row, index) => {
                // Use net amount from withdrawalData if available; otherwise, calculate it.
                const netAmount =
                  row.netAmount || calculateDeductions(row.amount).netAmount;
                console.log(row);
                return (
                  <TableRow
                    key={row.id}
                    sx={{ backgroundColor: "white", color: "black" }}
                  >
                    <TableCell sx={{ color: "black" }}>{index + 1}</TableCell>
                    <TableCell sx={{ color: "black" }}>E-Wallet</TableCell>
                    <TableCell sx={{ color: "black" }}>
                      {new Date(row.transactionDate).toLocaleDateString()}
                    </TableCell>
                    <TableCell sx={{ color: "black" }}>
                      {row.amount.toFixed(2)} ₹
                    </TableCell>{" "}
                    {/* Original amount */}
                    <TableCell sx={{ color: "black" }}>
                      {netAmount.toFixed(2)} ₹
                    </TableCell>{" "}
                    {/* Net amount after deductions */}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align="center"
                  sx={{ backgroundColor: "white", color: "black" }}
                >
                  No data available in table
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
        <Pagination count={10} variant="outlined" shape="rounded" />
      </Box>
    </Box>
  );
};

export default Withdrawals;
