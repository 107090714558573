import React, { createContext, useState, useEffect } from 'react';

export const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  // Initialize states with localStorage values if available
  const [id, setId] = useState(() => localStorage.getItem('id') || '');
  const [userName, setUserName] = useState(() => localStorage.getItem('userName') || '');
  const [email, setEmail] = useState(() => localStorage.getItem('email') || '');
  const [phoneNumber, setPhoneNumber] = useState(() => localStorage.getItem('phoneNumber') || '');
  const [userId, setUserId] = useState(() => localStorage.getItem('userId') || '');
  const [referralId, setReferralId] = useState(() => localStorage.getItem('referralId') || '');
  const [flag, setFlag] = useState(0);
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState(() => localStorage.getItem('colorMode') || '#03C9D7');
  const [currentMode, setCurrentMode] = useState(() => localStorage.getItem('themeMode') || 'Light');
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState({
    chat: false,
    cart: false,
    userProfile: false,
    notification: false,
  });
  const [team , setTeam] = useState([]); 

  // Save to localStorage whenever user data changes
  useEffect(() => {
    if (id) localStorage.setItem('id', id);
    if (userName) localStorage.setItem('userName', userName);
    if (email) localStorage.setItem('email', email);
    if (phoneNumber) localStorage.setItem('phoneNumber', phoneNumber);
    if (userId) localStorage.setItem('userId', userId);
    if (referralId) localStorage.setItem('referralId', referralId);
    localStorage.setItem('themeMode', currentMode);
    localStorage.setItem('colorMode', currentColor);
  }, [id, userName, email, phoneNumber, userId, referralId, currentMode, currentColor]);

  const setMode = (e) => {
    const mode = e.target.value;
    setCurrentMode(mode);
    localStorage.setItem('themeMode', mode);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem('colorMode', color);
  };

  return (
    <StateContext.Provider
      value={{
        id,
        setId,
        userName,
        setUserName,
        email,
        setEmail,
        phoneNumber,
        setPhoneNumber,
        userId,
        setUserId,
        referralId,
        setReferralId,
        flag,
        setFlag,
        currentColor,
        currentMode,
        themeSettings,
        setThemeSettings,
        activeMenu,
        setActiveMenu,
        screenSize,
        setScreenSize,
        isClicked,
        setIsClicked,
        setMode,
        setColor,
        team,
        setTeam
      }}
    >
      {children}
    </StateContext.Provider>
  );
};