import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faFileExport } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx"; // Import SheetJS for Excel export
import axios from "axios"; // Import axios for fetching data
import "./LevelIncome.css";

const LevelIncome = ({ currentUserId }) => {
  const [transactionData, setTransactionData] = useState([]); // Store transaction data
  const [filteredData, setFilteredData] = useState([]); // Store filtered data
  const [searchQuery, setSearchQuery] = useState(""); // Store search query
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const itemsPerPage = 10; // Number of items per page

  // Fetch data from API
  useEffect(() => {
    console.log("Fetching Level Income data for user:", currentUserId);

    const fetchData = async () => {
      try {
        if (!currentUserId) {
          setLoading(false);
          return;
        }

        // Fetch data for the logged-in user
        const response = await axios.get(
          ` https://backend.shnsm1.com/api/transactions/${currentUserId}`
        );

        console.log("Transaction Data:", response.data);

        if (Array.isArray(response.data) && response.data.length > 0) {
          setTransactionData(response.data);
          setFilteredData(response.data);
        } else {
          console.log("No transactions available for the user.");
          setTransactionData([]);
          setFilteredData([]);
        }
      } catch (error) {
        console.error("Error fetching transaction data:", error);
        setError("Failed to fetch transaction data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUserId]);

  // Format date to DD-MM-YYYY
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if no date is provided

    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return "Invalid Date";

      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return date.toLocaleDateString("en-GB", options); // Format as DD-MM-YYYY
    } catch {
      return "Invalid Date"; // Catch any unexpected errors
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter transaction data based on search query
    const filtered = transactionData.filter((row) => {
      const userIdMatch = row.userId?.toLowerCase().includes(query);
      const descriptionMatch = row.description?.toLowerCase().includes(query);
      const dateMatch = formatDate(row.date).includes(query);
      const amountMatch = row.amount?.toString().includes(query);
      return userIdMatch || descriptionMatch || dateMatch || amountMatch;
    });

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page after search
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Function to handle Excel export
  const exportToExcel = () => {
    const data = filteredData.map((row, index) => ({
      "Sr No.": index + 1,
      "User ID": row.userId,
      "Desc.": row.description,
      Date: formatDate(row.date),
      Amount: `${row.amount} ₹`,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "LevelIncome");
    XLSX.writeFile(workbook, "LevelIncome.xlsx");
  };

  // Display loading state
  if (loading) {
    return <div className="level-income-loading">Loading...</div>;
  }

  // Display error state
  if (error) {
    return <div className="level-income-error">{error}</div>;
  }

  // Render Level Income table or message
  return (
    <div className="level-income-page">
      <div className="level-income-header">
        <div className="level-income-breadcrumb">
          <h2>Level Income</h2>
          <div className="level-income-icon-with-text">
            <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
            <p>/ E Wallet</p>
          </div>
        </div>
      </div>

      <div className="level-income-table-section">
        <div className="level-income-table-controls">
          <button className="level-income-export-btn" onClick={exportToExcel}>
            <FontAwesomeIcon icon={faFileExport} /> Excel Export
          </button>
          <div className="level-income-search-filter">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="search"
              placeholder="Search by User ID, Description, Date, or Amount"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {filteredData.length > 0 ? (
          <>
            <table className="level-income-transaction-table">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>User ID</th>
                  <th>Desc.</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((row, index) => (
                  <tr key={row._id}>
                    <td data-label="Sr No.">{indexOfFirstItem + index + 1}</td>
                    <td data-label="User ID">{row.userId}</td>
                    <td data-label="Desc.">{row.description}</td>
                    <td data-label="Date">{formatDate(row.date)}</td>
                    <td data-label="Amount">{row.amount} ₹</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="level-income-pagination">
              <p>
                Showing {indexOfFirstItem + 1} to{" "}
                {Math.min(indexOfLastItem, filteredData.length)} of{" "}
                {filteredData.length} entries
              </p>
              <div className="level-income-pagination-controls">
                <button
                  className="level-income-pagination-btn"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  className="level-income-pagination-btn"
                  onClick={handleNextPage}
                  disabled={
                    currentPage >= Math.ceil(filteredData.length / itemsPerPage)
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="level-income-no-data">
            <p>No transactions available for this user.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LevelIncome;
