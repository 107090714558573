import React, { useState } from 'react';
import './Navbar.css';
import logo from '../../../Assets/Shnsm1.png';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import OpportunitiesPDF from '../../../Assets/shnsm2.pdf';
import { HiHome } from "react-icons/hi2"; 
import { AiFillProduct } from "react-icons/ai";
import { TiGroup } from "react-icons/ti";
import { TbHelpSquareFilled } from "react-icons/tb";
import { IoDocumentText } from "react-icons/io5";
import { FaUserLarge } from "react-icons/fa6";
import { MdTouchApp } from "react-icons/md";

const Navbar = () => {
  const [menuActive, setMenuActive] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <nav className='nav'>
      <img src={logo} alt='logo' className='logo' />

      <div className='hamburger' onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className={`nav-container ${menuActive ? 'active' : ''}`}>
        <ul className='dark-nav'>
          {isHomePage ? (
            <>
              <li className='nav-links'>
                <ScrollLink 
                  to="home" 
                  smooth={true} 
                  duration={500} 
                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                  <span className="nav-icon"><HiHome /></span> Home
                </ScrollLink>
              </li>
              <li className='nav-links'>
                <ScrollLink 
                  to="products" 
                  smooth={true} 
                  duration={500} 
                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                  <span className="nav-icon"><AiFillProduct /></span> Products
                </ScrollLink>
              </li>
              <li className='nav-links'>
                <ScrollLink 
                  to="about" 
                  smooth={true} 
                  duration={500} 
                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                  <span className="nav-icon"><TiGroup /></span> About Us
                </ScrollLink>
              </li>
              <li className='nav-links'>
                <ScrollLink 
                  to="contact" 
                  smooth={true} 
                  duration={500} 
                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                  <span className="nav-icon"><MdTouchApp /></span> Contact
                </ScrollLink>
              </li>
            </>
          ) : (
            <>
              <li className='nav-links'>
                <RouterLink to='/' style={{ textDecoration: 'none', cursor: 'pointer' }}>
                  <span className="nav-icon"><HiHome /></span> Home
                </RouterLink>
              </li>
              <li className='nav-links'>
                <RouterLink to='/#products' style={{ textDecoration: 'none', cursor: 'pointer' }}>
                  <span className="nav-icon"><AiFillProduct /></span> Products
                </RouterLink>
              </li>
              <li className='nav-links'>
                <RouterLink to='/#about' style={{ textDecoration: 'none', cursor: 'pointer' }}>
                  <span className="nav-icon"><TiGroup /></span> About Us
                </RouterLink>
              </li>
              <li className='nav-links'>
                <RouterLink to='/#contact' style={{ textDecoration: 'none', cursor: 'pointer' }}>
                  <span className="nav-icon"><MdTouchApp /></span> Contact
                </RouterLink>
              </li>
            </>
          )}
          <li className='nav-links'>
            <a href={OpportunitiesPDF} target="_blank" rel="noopener noreferrer">
              <span className="nav-icon"><IoDocumentText /></span> Info
            </a>
          </li>
        </ul>
      </div>

      <ul className='dark-nav login-signup'>
        <li className='nav-links'>
          <RouterLink to='/login' style={{ textDecoration: 'none', cursor: 'pointer' }}>
            <span className="nav-icon"><FaUserLarge/></span>  Login
          </RouterLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
