import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../contexts/ContextProvider";
import axios from "axios";
import { FaUser, FaCheckCircle, FaTimesCircle, FaBan } from "react-icons/fa";
import "./LevelTree.css";

const LevelTree = () => {
  const { userId, userName, setTeam } = useContext(StateContext);

  const [users, setUsers] = useState([]);
  const [levels, setLevels] = useState([[], [], [], [], []]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          " https://backend.shnsm1.com/api/users/data/all"
        );
        const allUsers = response.data;

        // Calculate users for each level, including both blocked and unblocked users
        const level1Users = allUsers.filter(
          (user) => user.referralId === userId
        );
        const level2Users = allUsers.filter((user) =>
          level1Users.some(
            (level1User) => level1User.userId === user.referralId
          )
        );
        const level3Users = allUsers.filter((user) =>
          level2Users.some(
            (level2User) => level2User.userId === user.referralId
          )
        );
        const level4Users = allUsers.filter((user) =>
          level3Users.some(
            (level3User) => level3User.userId === user.referralId
          )
        );
        const level5Users = allUsers.filter((user) =>
          level4Users.some(
            (level4User) => level4User.userId === user.referralId
          )
        );

        setLevels([
          level1Users,
          level2Users,
          level3Users,
          level4Users,
          level5Users,
        ]);

        // Merge all levels into the team array
        setTeam([
          ...level1Users,
          ...level2Users,
          ...level3Users,
          ...level4Users,
          ...level5Users,
        ]);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchUsers();
  }, [userId]);

  const calculateActiveInactive = (level) => {
    const activeMembers = level.filter((user) => !user.blocked); // Active users are not blocked
    const inactiveMembers = level.filter((user) => user.blocked); // Inactive users are blocked
    return {
      total: level.length,
      active: activeMembers.length,
      inactive: inactiveMembers.length,
    };
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Formats as MM/DD/YYYY or DD/MM/YYYY based on locale
  };

  return (
    <div className="light-level-tree">
      <div className="main-user">
        <div className="main-user-card">
          <FaUser className="user-icon main-icon" />
          <div className="user-details">
            <p className="user-id">ID: {userId}</p>
            <p className="user-name">Name: {userName}</p>
          </div>
        </div>
      </div>

      {levels.map((level, levelIndex) => {
        const { total, active, inactive } = calculateActiveInactive(level);

        return (
          <div key={levelIndex} className="light-level">
            <div className="level-info">
              <h3 className="level-title">LEVEL {levelIndex + 1}</h3>
              <p className="level-stats">Total: {total}</p>
              <p className="level-stats">
                Active: <FaCheckCircle className="status-icon" /> {active}
              </p>
              <p className="level-stats">
                Inactive: <FaTimesCircle className="status-icon" /> {inactive}
              </p>
            </div>

            <div className="user-list">
              {level.map((user, index) => (
                <div
                  key={index}
                  className={`user-card ${user.blocked ? "blocked-user" : ""}`}
                >
                  <FaUser className="user-icon" />
                  <div className="user-details">
                    <p className="user-id">ID: {user.userId}</p>
                    <p className="user-name">Name: {user.userName}</p>
                    <p className="user-referral-id">
                      Referral ID: {user.referralId}
                    </p>
                    <p className="user-joining-date">
                      Date of Joining: {formatDate(user.dateOfJoining)}
                    </p>{" "}
                    {/* Display date only */}
                    {user.blocked ? (
                      <FaBan
                        className="status-icon blocked-icon"
                        title="Blocked"
                      />
                    ) : (
                      <FaCheckCircle
                        className="status-icon active-icon"
                        title="Active"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LevelTree;
