import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ForgotPassword.css";
import axios from "axios";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        " https://backend.shnsm1.com/api/users/forgot-password",
        { email }
      );

      if (response.status === 200) {
        alert("Reset link sent to your email");
        navigate("/reset-password");
      } else {
        alert(response.data.message || "Error sending reset link");
      }
    } catch (err) {
      alert("Error sending reset link");
    }
  };

  return (
    <div className="forgot-pw-container">
      <form onSubmit={handleForgotPasswordSubmit} className="forgot-pw-form">
        <h1 className="forgot-pw-header">Forgot Password</h1>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="forgot-pw-input"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button type="submit" className="forgot-pw-button">
          Send Reset Link
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </form>
    </div>
  );
}

export default ForgotPassword;
