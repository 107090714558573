import React, { useState, useEffect } from "react";
import "./AllTransactions.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faHome,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import * as XLSX from "xlsx"; // For Excel export

const AllTransactions1 = () => {
  const [filterVisible, setFilterVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateFilterEnabled, setDateFilterEnabled] = useState(false);
  const [fundTransfers, setFundTransfers] = useState([]);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [filteredTransfers, setFilteredTransfers] = useState([]); // Filtered data

  useEffect(() => {
    const fetchFundTransfers = async () => {
      try {
        const response = await axios.get(
          " https://backend.shnsm1.com/api/fund-transfers"
        );
        const data = response.data;
        setFundTransfers(data);

        const creditAmount = data
          .filter((transfer) => transfer.method === "credit")
          .reduce((acc, transfer) => acc + transfer.amount, 0);
        setTotalCredit(creditAmount);

        const debitAmount = data
          .filter((transfer) => transfer.method === "debit")
          .reduce((acc, transfer) => acc + transfer.amount, 0);
        setTotalDebit(debitAmount);

        setFilteredTransfers(data); // Initialize filtered data
      } catch (error) {
        console.error("Error fetching fund transfers:", error);
      }
    };

    fetchFundTransfers();
  }, []);

  const handleCheckboxChange = () => {
    setDateFilterEnabled(!dateFilterEnabled);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSubmit = () => {
    let filteredData = fundTransfers;

    if (dateFilterEnabled && startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      filteredData = filteredData.filter((transfer) => {
        const transferDate = new Date(transfer.transactionDate);
        return transferDate >= start && transferDate <= end;
      });
    }

    setFilteredTransfers(filteredData);
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setDateFilterEnabled(false);
    setFilteredTransfers(fundTransfers); // Reset to original data
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    const filteredData = fundTransfers.filter(
      (transfer) =>
        transfer.userId.toLowerCase().includes(e.target.value.toLowerCase()) ||
        transfer.description
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );

    setFilteredTransfers(filteredData);
  };

  const handleExport = () => {
    const data = filteredTransfers.map((transfer, index) => ({
      "Sr No.": index + 1,
      "User ID": transfer.userId,
      Description: transfer.description,
      Date: new Date(transfer.transactionDate).toLocaleDateString("en-GB"),
      Method: transfer.method,
      Amount: `${transfer.method === "credit" ? "+" : "-"} ${
        transfer.amount
      } ₹`,
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");
    XLSX.writeFile(workbook, "Transactions.xlsx");
  };

  return (
    <div className="transaction-page">
      <div className="header">
        <div className="breadcrumb">
          <h2 style={{ color: "black" }}>All Transactions</h2>
          <div className="icon-with-text">
            <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
            <p>/ Wallet / E Wallet</p>
          </div>
        </div>
      </div>

      <div className="table-section">
        <table className="summary-table">
          <thead>
            <tr>
              <th>CREDIT</th>
              <th>DEBIT</th>
              <th>AVAILABLE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="credit">+ {totalCredit} ₹</td>
              <td className="debit red-text">- {totalDebit} ₹</td>
              <td className="available">{totalCredit - totalDebit} ₹</td>
            </tr>
          </tbody>
        </table>

        <div className="table-controls">
          <button className="export-btn" onClick={handleExport}>
            <FontAwesomeIcon icon={faFileExport} /> Excel Export
          </button>
          <div className="search-filter">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="search"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>

        <table className="transaction-table">
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>User ID</th>
              <th>Description</th>
              <th>Date</th>
              <th>Method</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransfers.length > 0 ? (
              filteredTransfers.map((transfer, index) => (
                <tr key={transfer._id}>
                  <td>{index + 1}</td>
                  <td>{transfer.userId}</td>
                  <td>{transfer.description}</td>
                  <td>
                    {new Date(transfer.transactionDate).toLocaleDateString(
                      "en-GB"
                    )}
                  </td>{" "}
                  {/* day-month-year format */}
                  <td>{transfer.method}</td>
                  <td
                    className={
                      transfer.method === "credit" ? "credit" : "debit red-text"
                    }
                  >
                    {transfer.method === "credit"
                      ? `+ ${transfer.amount} ₹`
                      : `- ${transfer.amount} ₹`}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="no-data">
                  No data available in table
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="pagination">
          <p>
            Showing 0 to {filteredTransfers.length} of{" "}
            {filteredTransfers.length} entries
          </p>
          <div className="pagination-controls">
            <button className="pagination-btn">Previous</button>
            <button className="pagination-btn">Next</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTransactions1;
