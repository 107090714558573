import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/user/footer/Footer';
import Home from './components/user/home/Home';
import LoginSignup from './components/user/login&signup/LoginSignup';
import ForgotPassword from './components/user/forgotpassword/ForgotPassword';
import ResetPassword from './components/user/resetpassword/ResetPassword';
import Navbar from './components/user/navbar/Navbar';
import Products from './components/user/products/Products';
import AboutUs from './components/user/about/About';
import ContactUs from './components/user/contactus/Contactus';
import Sidebar from './components/user/global/Sidebar';
import Dashboard from './components/user/dashboard/Dashboard';
import WelcomeLetter from './components/user/welcomeLetter/WelcomeLetter';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import PersonalDetail from './components/user/personaldetails/PersonalDetail';
import IncomeReport from './components/user/incomereport/IncomeReport';
import BankDetailsForm from './components/user/bankdetails/BankDetailsForm';
import ChangePassword from './components/user/changepassword/ChangePassword';
import Shop from './components/user/shop/Shop';
import LevelTree from './components/user/leveltreeu/LevelTree';
import MyTeam from './components/user/myteam/MyTeam';
import Withdrawals from './components/user/withdrawls/Withdrawals';
import FundRequest from './components/user/fundrequest/FundRequest';
import Reward from './components/user/reward/Reward';
import AllTransactions from './components/user/alltransactions/AllTransactions';
import LevelIncome from './components/user/levelincome/LevelIncome';
import ThemeSettings from './components/user/themesetting/ThemeSettings';
import { StateContext } from './contexts/ContextProvider';
import Topbar from './components/user/global/Topbar';
import Sidebar1 from './components/admin/sidebar1/Sidebar1'
import AdminDashboard from './components/admin/admindashboard/AdminDashboard';
import LevelTree1 from './components/admin/leveltree/LevelTree1';
import MyTeam1 from './components/admin/myteam1/MyTeam1';
import CreditDebitFund from './components/admin/wallet/creditdebitfund/CreditDebitFund';
import './App.css';
import News from './components/admin/news/News';
import News1 from './components/user/news/News';
import FundRequest1 from './components/admin/fundrequest/FundRequest1';
import AllTransactions1 from './components/admin/alltransactions/AllTransactions1';
// import LevelIncome1 from './components/admin/levelincome/LevelIncome1';
import RewardMatching from './components/admin/rewardmatching/RewardMatching';
import BlockUnblock1 from './components/admin/blockunblock/BlockUnblock1';
import Kyc from './components/admin/kyc/Kyc';
import ActivateReport from './components/admin/activatereport/ActivateReport';
import BalanceReport from './components/admin/balancereport/BalanceReport'
import IncomeReport1 from './components/admin/incomereport/IncomeReport1'
import RewardsReport from './components/admin/rewardsreport/RewardsReport';
import Payout from './components/admin/payout/Payout';
import ContactUsReport from './components/admin/contactreport/ContactUsReport';
import AchieversTable from './components/admin/Achievers/Achiever';
import DisplayAchivers from './components/user/AchiversDisplay/DisplayAchivers';
import AdminTopbar from './components/admin/global/AdminTopbar';


function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { flag, currentColor, currentMode, activeMenu, themeSettings, setThemeSettings, userId } = useContext(StateContext);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <Router>
      <Routes>
      <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
      </Routes>

        {flag < 1 && (
          <>
            <Navbar />
            <Routes className='home'>
              <Route path="/" element={<><Home /><Products /><AboutUs /><DisplayAchivers /><News1 /><ContactUs /></>} />
              <Route path="/login" element={<LoginSignup />} />
            </Routes>
            <Footer />
          </>
        )}

        {flag === 2 && (
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <div className="fayaz">
                <Sidebar isSidebar={isSidebar} className="sidebar" />
                <div className="surendra">
                  <Topbar setIsSidebar={setIsSidebar} className="topbar" />
                  <main className="content">
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/welcome" element={<WelcomeLetter />} />
                      <Route path="/shop" element={<Shop />} />
                      <Route path="/leveltree" element={<LevelTree />} />
                      <Route path="/myteam" element={<MyTeam />} />
                      <Route path="/alltransactions" element={<AllTransactions  userId={userId}/>} />
                      <Route path="/levelIncome" element={<LevelIncome currentUserId={userId}/>} />
                      <Route path="/fundrequest" element={<FundRequest />} />
                      <Route path="/incomereport" element={<IncomeReport />} />
                      <Route path="/reward" element={<Reward />} />
                      <Route path="/withdrawls" element={<Withdrawals userId={userId}/>} />
                      <Route path="/personaldetail" element={<PersonalDetail />} />
                      <Route path="/bankdetail" element={<BankDetailsForm />} />
                      <Route path="/changepassword" element={<ChangePassword />} />
                      {/* Add ThemeSettings route if necessary */}
                      <Route path="/themesettings" element={<ThemeSettings />} />
                      <Route path="/forgot-password" element={<ForgotPassword />} />
                      <Route path="/reset-password" element={<ResetPassword />} />
                    </Routes>
                  </main>
                </div>
              </div>
            </ThemeProvider>
          </ColorModeContext.Provider>
        )}

        {flag >= 1 && flag !== 2 && (
          <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="fayaz">
              <Sidebar1 isSidebar={isSidebar} className="sidebar" />
              <div className="surendra">
                <AdminTopbar setIsSidebar={setIsSidebar} className="admin-topbar" />
                <main className="content">
                  <Routes>
                    <Route path="/" element={<AdminDashboard/>} />
                    <Route path="/adashboard" element={<AdminDashboard />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/ewallet" element={<AllTransactions1 />} />   
                    <Route path="/leveltree1" element={<LevelTree1 />} />                 
                    <Route path="/myteam1" element={<MyTeam1 />} />     
                    <Route path="/creditdebit" element={<CreditDebitFund />} />     
                    <Route path="/fundrequest1" element={<FundRequest1 />} />     
                    {/* <Route path="/levelincome" element={<LevelIncome1 />} />      */}
                    <Route path="/rewardincome" element={<RewardMatching />} />     
                    <Route path="/block" element={<BlockUnblock1 />} />  
                    <Route path="/kyc" element={<Kyc />} />    
                    <Route path="/activatereport" element={<ActivateReport />} />     
                    <Route path="/balancereport" element={<BalanceReport />} />     
                    <Route path="/incomereport" element={<IncomeReport1 />} />     
                    <Route path="/rewardsreport" element={<RewardsReport />} />     
                    <Route path="/payout" element={<Payout />} />    
                    <Route path="/contactus" element={<ContactUsReport />} />  
                    <Route path="/news" element={<News />} />  {/* News Route added */}
                    <Route path="/AchieversTable" element={<AchieversTable />} />
                    <Route path="/themesettings" element={<ThemeSettings />} />
                  </Routes>
                </main>
              </div>
            </div>
          </ThemeProvider>
        </ColorModeContext.Provider>
        )}
      </Router>
    </div>
  );
}

export default App;