import React, { useState, useEffect } from "react";
import axios from "axios";
import "./DisplayAchivers.css";

const DisplayAchivers = () => {
  const [achievers, setAchievers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchAchievers();
  }, []);

  const fetchAchievers = async () => {
    try {
      const res = await axios.get(" https://backend.shnsm1.com/api/achievers/");
      setAchievers(res.data);
      setError(null);
    } catch (err) {
      console.error("Error fetching achievers:", err);
      setError("Failed to fetch achievers");
    }
  };

  // Filter achievers with images and limit to top four achievers
  const displayedAchievers = achievers
    .filter((achiever) => achiever.image)
    .slice(0, 4);

  return (
    <div id="achievers">
      <div className="achievers-container">
        {/* Error message display */}
        {error && <div className="error-message">{error}</div>}

        {/* Conditionally render the title if there are achievers with images */}
        {displayedAchievers.length > 0 && (
          <h1 className="achievers-title">Achievers</h1>
        )}

        <div className="achievers-grid">
          {displayedAchievers.map((achiever) => (
            <div key={achiever._id} className="achiever-card">
              <div className="image-container">
                <img
                  src={achiever.image}
                  alt={achiever.name}
                  className="achiever-image"
                  onError={(e) => {
                    e.target.src = "placeholder-image-url"; // Replace with actual placeholder URL
                    e.target.alt = "Image not found";
                  }}
                />
              </div>
              <div className="achiever-content">
                <h3 className="achiever-name">{achiever.name}</h3>
                <p className="achiever-description">{achiever.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DisplayAchivers;
