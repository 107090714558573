import React from 'react';
import DataTable from 'react-data-table-component';
import './IncomeReport.css';
import * as XLSX from 'xlsx';

const IncomeReport = () => {
  // Sample data
  const data = []; // Removed the sample data entries

  const columns = [
    {
      name: 'Sr. No.',
      selector: row => row.id,
      sortable: true,
    },
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
    },
    {
      name: 'Level Income',
      selector: row => row.levelIncome,
      sortable: true,
    },
    {
      name: 'Reward_matching Income',
      selector: row => row.rewardIncome,
      sortable: true,
    },
  ];

  const exportToExcel = () => {
    // Create an array to hold the header
    const header = [
      'Sr. No.',
      'Date',
      'Level Income',
      'Reward_matching Income',
    ];

    // Create an array of data with indices for "Sr. No."
    const formattedData = data.map((row, index) => ({
      'Sr. No.': index + 1,
      'Date': row.date,
      'Level Income': row.levelIncome,
      'Reward_matching Income': row.rewardIncome,
    }));

    // Combine header with data for export
    const combinedData = [header, ...formattedData.map(item => [item['Sr. No.'], item['Date'], item['Level Income'], item['Reward_matching Income']])];

    // Convert the data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(combinedData); // Change here to aoa_to_sheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Income Report");

    // Write the workbook to a file
    XLSX.writeFile(workbook, "IncomeReport.xlsx");
  };

  return (
    <div className="income-report-container">
      <header className="header">
        <h2>Income Report</h2>
        <nav className="breadcrumb">
          <span>Home</span> / <span>Income Report</span>
        </nav>
      </header>

      <div className="income-summary">
        <div className="summary-box">
          <h4>Total Level Income</h4>
        </div>
        <div className="summary-box">
          <h4>Total Reward_matching Income</h4>
        </div>
      </div>

      <div className="table-section">
        <button className="export-button" onClick={exportToExcel}>Excel Export</button>
        
        {/* New HTML table for displaying data */}
        <table className="income-table">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Date</th>
              <th>Level Income</th>
              <th>Reward_matching Income</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan="4">No data available</td>
              </tr>
            ) : (
              data.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.date}</td>
                  <td>{row.levelIncome}</td>
                  <td>{row.rewardIncome}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        <DataTable
          columns={columns}
          data={data}
          noDataComponent="No data available in table"
          pagination
        />
      </div>
    </div>
  );
};

export default IncomeReport;
