import React, { useContext, useState } from 'react';
import { Box, IconButton, Popover, Typography, Button, Avatar, useMediaQuery } from '@mui/material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { StateContext } from '../../../contexts/ContextProvider';
import { useNavigate } from 'react-router-dom';

const Topbar = () => {
  const navigate = useNavigate();
  const {
    userId, setUserId,
    flag, setFlag,
    userName, setUserName,
    email, setEmail,
    phoneNumber, setPhoneNumber,
    referralId, setReferralId,
  } = useContext(StateContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setUserId(null);
    setUserName(null);
    setEmail(null);
    setPhoneNumber(null);
    setReferralId(null);
    setFlag(0);
    navigate('/login');
  };

  const open = Boolean(anchorEl);
  
  // Mobile, tablet, and larger screen breakpoints using MUI's useMediaQuery
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');

  return (
    <Box 
      display="flex" 
      justifyContent="space-between" 
      p={2} 
      bgcolor="#121212"
      flexDirection={isMobile ? 'column' : 'row'} // Stack elements on small screens
      alignItems={isMobile ? 'center' : 'flex-start'} // Center the content on small screens
    >
      <Box 
        display="flex" 
        backgroundColor="#333333" 
        borderRadius="3px" 
        px={2}
        mb={isMobile ? 2 : 0} // Add bottom margin for mobile devices to space out elements
      >
        <Typography 
          variant="h1" 
          sx={{ color: "#ffffff", fontSize: isMobile ? '1.5rem' : '2rem' }} // Smaller font size on mobile
        >
          🙏 Namaskaram {userName || 'User'}, Welcome to SHNSM-I
        </Typography>
      </Box>

      <Box 
        display="flex"
        justifyContent={isMobile ? 'center' : 'flex-end'} // Align icons differently on mobile
        width={isMobile ? '100%' : 'auto'} // Full width on mobile for better spacing
      >
        <IconButton sx={{ color: "#ffffff" }}>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton 
          onClick={handlePopoverOpen} 
          sx={{ color: "#ffffff" }}
        >
          <PersonOutlinedIcon />
        </IconButton>
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box 
          p={2} 
          display="flex" 
          flexDirection="column" 
          alignItems="center" 
          bgcolor="#333333" 
          color="#ffffff"
          width={isMobile ? '100vw' : 'auto'} // Full width on mobile
        >
          <Avatar 
            alt="User Profile Picture" 
            src="" // Add a valid src if you have user image, otherwise this shows the default image
            sx={{ width: 64, height: 64, mb: 2 }}
          >
            {userName ? userName[0] : 'U'} {/* Show first letter of username if available */}
          </Avatar>
          <Typography variant="h6" sx={{ mb: 1 }}>
            User Details:
          </Typography>
          <Typography variant="body2">
            <strong>User ID:</strong> {userId || 'Not Available'}
          </Typography>
          <Typography variant="body2">
            <strong>Username:</strong> {userName || 'Not Available'}
          </Typography>
          <Typography variant="body2">
            <strong>Email:</strong> {email || 'Not Available'}
          </Typography>
          <Typography variant="body2">
            <strong>Phone Number:</strong> {phoneNumber || 'Not Available'}
          </Typography>
          <Typography variant="body2">
            <strong>Referral ID:</strong> {referralId || 'Not Available'}
          </Typography>
          <Button 
            variant="outlined" 
            startIcon={<LogoutIcon />} 
            onClick={handleLogout} 
            color="secondary" 
            sx={{ mt: 2, color: "#ffffff", borderColor: "#ffffff" }}
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

export default Topbar;
