import React, { useState, useEffect } from "react";
import axios from "axios";
import "./FundRequest.css";

const FundRequest1 = () => {
  const [fundRequests, setFundRequests] = useState([]);
  const [search, setSearch] = useState("");
  const [userBalances, setUserBalances] = useState({});
  const [bankDetails, setBankDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState({}); // Stores payment method for each request

  // Fetch fund requests and user balances
  useEffect(() => {
    const fetchFundRequests = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          " https://backend.shnsm1.com/api/fund-request/fund-requests/"
        );
        setFundRequests(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        setError("Error fetching fund requests.");
        console.error("Error fetching fund requests:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUserBalances = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          " https://backend.shnsm1.com/api/users/data/all"
        );
        const balances = response.data.reduce((acc, user) => {
          acc[user.userId] = user.walletBalance;
          return acc;
        }, {});
        setUserBalances(balances);
      } catch (error) {
        setError("Error fetching user balances.");
        console.error("Error fetching user balances:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFundRequests();
    fetchUserBalances();
  }, []);

  // Fetch bank details for all requests when the component mounts
  useEffect(() => {
    const fetchAllBankDetails = async () => {
      const userIds = [
        ...new Set(fundRequests.map((request) => request.userId)),
      ];
      for (const userId of userIds) {
        if (!bankDetails[userId]) {
          try {
            const response = await axios.get(
              ` https://backend.shnsm1.com/api/bank-details/${userId}`
            );
            setBankDetails((prevDetails) => ({
              ...prevDetails,
              [userId]: response.data,
            }));
          } catch (error) {
            console.error(
              `Error fetching bank details for user ${userId}:`,
              error
            );
          }
        }
      }
    };
    fetchAllBankDetails();
  }, [fundRequests]); // Run when fundRequests change

  const updateRequestStatus = async (requestId, status) => {
    try {
      await axios.put(
        ` https://backend.shnsm1.com/api/fund-request/status/${requestId}`,
        {
          status,
        }
      );
      setFundRequests((prevRequests) =>
        prevRequests.filter((req) => req._id !== requestId)
      );
    } catch (error) {
      setError(
        `Failed to update request status to ${status}. Please try again.`
      );
      console.error(`Error updating request status to ${status}:`, error);
    }
  };

  const calculateNetAmount = (amount) => {
    const tds = (10 / 100) * amount; // 10% TDS
    const adminCharges = (5 / 100) * amount; // 5% Admin Charges
    const netAmount = amount - tds - adminCharges;
    return netAmount;
  };

  const handleApprove = async (request) => {
    const confirmed = window.confirm(
      "Are you sure you want to approve this request?"
    );
    if (confirmed) {
      const userId = request.userId;
      const requestedAmount = request.amount;
      const netAmount = calculateNetAmount(requestedAmount);
      const currentBalance = userBalances[userId] || 0;

      if (currentBalance >= requestedAmount) {
        try {
          const newBalance = currentBalance - requestedAmount;

          // Update user balance
          await axios.put(
            ` https://backend.shnsm1.com/api/users/api/users/${userId}`,
            {
              amount: requestedAmount,
              method: "debit",
              description: `Fund request approved for User ID: ${userId}`,
            }
          );

          // Update request status
          await updateRequestStatus(request._id, "Approved");

          // Log transaction
          const transactionDate = new Date().toISOString();
          await axios.post(" https://backend.shnsm1.com/api/fund-transfers", {
            userId,
            amount: requestedAmount,
            description: `Fund request approved for User ID: ${userId} (Original amount)`,
            method: "debit",
            transactionDate,
            paymentMethod: paymentMethods[request._id], // Include payment method
          });

          alert("Request approved and balance updated successfully!");

          // Update user balances in state
          const updatedBalances = { ...userBalances, [userId]: newBalance };
          setUserBalances(updatedBalances);
          console.log(paymentMethods);

          // Refetch fund requests to reflect the approved status
          const updatedRequests = await axios.get(
            " https://backend.shnsm1.com/api/fund-request/fund-requests/"
          );
          setFundRequests(updatedRequests.data); // Refetch the fund requests list
        } catch (error) {
          console.error("Error details:", error);
          setError("Failed to approve request. Please try again.");
        }
      } else {
        alert("Insufficient balance to approve this request.");
      }
    }
  };

  const handleReject = async (request) => {
    const confirmed = window.confirm(
      "Are you sure you want to reject this request?"
    );
    if (confirmed) {
      try {
        await updateRequestStatus(request._id, "Rejected");
        alert("Request rejected successfully!");
      } catch (error) {
        setError("Failed to reject request. Please try again.");
        console.error("Error rejecting request:", error);
      }
    }
  };

  const filteredRequests = fundRequests.filter((request) =>
    request.userId?.toLowerCase().includes(search.toLowerCase())
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="body1">
      <h2>Fund Requests</h2>
      <div className="top-bar">
        <input
          type="text"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Request By</th>
              <th>Wallet Balance</th>
              <th>Requested Amount</th>
              <th>Requested Amount (After Deductions)</th>

              <th>Bank Details</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredRequests.map((request, index) => {
              const walletBalance = userBalances[request.userId] || 0;
              const netAmount = calculateNetAmount(request.amount);

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <div>
                      ID: {request.userId}
                      <br />
                      Name: {request.userName}
                    </div>
                  </td>
                  <td>₹ {walletBalance}</td>
                  <td>₹ {request.amount}</td>
                  <td>₹ {netAmount.toFixed(2)}</td>

                  <td>
                    <p>
                      <b>Account No:</b>{" "}
                      {bankDetails[request.userId]?.accountNumber || "N/A"}
                    </p>
                    <p>
                      <b>Account Holder Name:</b>{" "}
                      {bankDetails[request.userId]?.accountHolderName || "N/A"}
                    </p>
                    <p>
                      <b>Bank Name:</b>{" "}
                      {bankDetails[request.userId]?.bankName || "N/A"}
                    </p>
                    <p>
                      <b>Branch Name:</b>{" "}
                      {bankDetails[request.userId]?.branchName || "N/A"}
                    </p>
                    <p>
                      <b>IFSC Code:</b>{" "}
                      {bankDetails[request.userId]?.ifscCode || "N/A"}
                    </p>
                    <p>
                      <b>Google Pay:</b>{" "}
                      {bankDetails[request.userId]?.gpayNumber || "N/A"}
                    </p>
                    <p>
                      <b>Phone Pe:</b>{" "}
                      {bankDetails[request.userId]?.phonePeNumber || "N/A"}
                    </p>
                    <p>
                      <b>UPI ID:</b>{" "}
                      {bankDetails[request.userId]?.upiId || "N/A"}
                    </p>
                  </td>
                  <td>{new Date(request.createdAt).toLocaleString()}</td>
                  <td>
                    <button
                      className="approved"
                      onClick={() => handleApprove(request)}
                    >
                      &#10003; {/* Approved icon */}
                    </button>
                    <button
                      className="rejected"
                      onClick={() => handleReject(request)}
                    >
                      &#10005; {/* Rejected icon */}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FundRequest1;
