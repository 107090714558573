import React, { useEffect, useState } from "react";
import { FaUpload } from "react-icons/fa";
import "./PersonalDetail.css";

const PersonalDetail = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    aadhaarNumber: "",
    panNumber: "",
    aadhaarFile: null,
    panFile: null,
    status: "Pending",
  });

  useEffect(() => {
    for (const key in localStorage) {
      const status = localStorage.getItem(key);
      if (status === "Approved" || status === "Declined") {
        setFormData((prevData) => ({ ...prevData, status }));
        localStorage.removeItem(key);
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("email", formData.email);
    formDataObj.append("phone", formData.phone);
    formDataObj.append("address", formData.address);
    formDataObj.append("city", formData.city);
    formDataObj.append("state", formData.state);
    formDataObj.append("aadhaarNumber", formData.aadhaarNumber);
    formDataObj.append("panNumber", formData.panNumber);
    if (formData.aadhaarFile)
      formDataObj.append("aadhaarFile", formData.aadhaarFile);
    if (formData.panFile) formDataObj.append("panFile", formData.panFile);

    try {
      const response = await fetch(
        " https://backend.shnsm1.com/api/details/Details",
        {
          method: "POST",
          body: formDataObj,
        }
      );
      if (response.ok) {
        alert("Details submitted successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          address: "",
          city: "",
          state: "",
          aadhaarNumber: "",
          panNumber: "",
          aadhaarFile: null,
          panFile: null,
          status: "Pending",
        });
      } else {
        const errorText = await response.text();
        alert(`Error submitting details: ${errorText}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting the details.");
    }
  };

  return (
    <div className="personal-detail">
      <div className="container-details">
        <div className="details-content">
          <form
            className="details-form"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="details-profile-section">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                className="details-input"
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email address"
                className="details-input"
                required
              />
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone No."
                className="details-input"
                required
              />
            </div>

            <textarea
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="Address"
              className="details-input details-textarea"
              required
            ></textarea>

            <div className="details-row">
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="City"
                className="details-input details-half-width"
                required
              />
              <select
                name="state"
                value={formData.state}
                onChange={handleChange}
                className="details-input details-half-width details-select"
                required
              >
                <option value="">Select State</option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
                <option value="Andaman and Nicobar Islands">
                  Andaman and Nicobar Islands
                </option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Dadra and Nagar Haveli and Daman and Diu">
                  Dadra and Nagar Haveli and Daman and Diu
                </option>
                <option value="Delhi">Delhi</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Ladakh">Ladakh</option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="Puducherry">Puducherry</option>
              </select>
            </div>

            <div className="details-row">
              <input
                type="text"
                name="aadhaarNumber"
                value={formData.aadhaarNumber}
                onChange={handleChange}
                placeholder="Aadhaar No."
                className="details-input"
                required
              />
              <input
                type="text"
                name="panNumber"
                value={formData.panNumber}
                onChange={handleChange}
                placeholder="PAN No."
                className="details-input"
                required
              />
            </div>

            <div className="details-upload-section">
              <div className="details-upload-container">
                <FaUpload size={50} className="icon-blue" />
                <label htmlFor="aadhaarFile" className="details-upload-btn">
                  Upload Aadhaar
                </label>
                <input
                  type="file"
                  id="aadhaarFile"
                  name="aadhaarFile"
                  onChange={handleFileChange}
                  className="details-file-input"
                  required
                />
              </div>
              <div className="details-upload-container">
                <FaUpload size={50} className="icon-blue" />
                <label htmlFor="panFile" className="details-upload-btn">
                  Upload PAN
                </label>
                <input
                  type="file"
                  id="panFile"
                  name="panFile"
                  onChange={handleFileChange}
                  className="details-file-input"
                  required
                />
              </div>
            </div>

            <button type="submit" className="details-submit-btn">
              Submit
            </button>
          </form>

          {/* Status Display Section */}
          <div className="details-status-section">
            <h3>Submission Status: {formData.status}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetail;
