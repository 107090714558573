import React, { useState } from 'react';
import './RewardsReport.css';

const RewardsReport = () => {
  const [search, setSearch] = useState('');

  return (
    <div className="report-container">
      <header className="report-header">
        <h3>Reward Matching Report</h3>
        <p className="report-breadcrumb">/ Reward Matching Report</p>
      </header>

      <div className="filter-section">
        <button className="filter-button">Filter</button>
      </div>

      <div className="summary-section">
        <table className="summary-table">
          <thead>
            <tr>
              <th>MATCHING</th>
              <th>GROSS AMOUNT</th>
              <th>DEDUCTION</th>
              <th>NET AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="4"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="controls-section">
        <button className="back-button">← Back</button>
        <button className="export-button">Excel Export</button>
        <input 
          type="text" 
          className="search-input" 
          placeholder="Search" 
          value={search} 
          onChange={(e) => setSearch(e.target.value)} 
        />
      </div>

      <div className="data-table-section">
        <table className="data-table">
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>User</th>
              <th>Date</th>
              <th>Total Company KP</th>
              <th>Total Company Turnover</th>
              <th>Total User KP</th>
              <th>Left</th>
              <th>Right</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="8">No data available in table</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="pagination-section">
        <span>Showing 0 to 0 of 0 entries</span>
        <div className="pagination-buttons">
          <button className="pagination-button">Previous</button>
          <button className="pagination-button">Next</button>
        </div>
      </div>
    </div>
  );
};

export default RewardsReport;