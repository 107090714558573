let data_product1 = [
  { id: 1, name: "పూజ సామాగ్రి" },
  { id: 2, name: "పప్పులు" },
  { id: 3, name: "రవ్వలు" },
  { id: 4, name: "చిరు ధాన్యాలు" },
  { id: 5, name: "డ్రై ఫ్రూట్స్" },
  { id: 6, name: "మసాలాలు" },
  { id: 7, name: "టూత్ బ్రెష్" },
  { id: 8, name: "టూత్ పేస్ట్" },
  { id: 9, name: "ఆయిల్స్" },
  { id: 10, name: "హేయిర్ ఆయిల్స్" },
  { id: 11, name: "షాంపోస్" },
  { id: 12, name: "హేయిర్ డై" },
  { id: 13, name: "ఫేస్ క్రీమ్స్" },
  { id: 14, name: "డిటర్జంట్ సోప్స్" },
  { id: 15, name: "డిష్వాష్ లిక్విడ్స్" },
  { id: 16, name: "డిటర్జెంట్ పౌడర్స్" },
  { id: 17, name: "బాత్ సోప్స్" },
  { id: 18, name: "సానిటరి ప్యాడ్స్" },
  { id: 19, name: "సాల్ట్స్" },
  { id: 20, name: "కూల్ డ్రింక్స్" },
  { id: 21, name: "పిండీలు" },
  { id: 22, name: "ఫ్లోర్ క్లీనర్" },
  { id: 23, name: "చిప్స్" },
  { id: 24, name: "బిస్కెట్స్" },
  { id: 25, name: "డిటర్జెట్ లిక్విడ్స్" },
];

export default data_product1;
