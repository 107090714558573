import React, { useState } from "react";
import axios from "axios";
import "./ContactUs.css";
import { FaPhoneAlt, FaAddressCard } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { MdConnectWithoutContact } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;

    if (!formData.name.trim()) {
      toast.error("Name is required");
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      toast.error("Email is required");
      isValid = false;
    } else if (!emailRegex.test(formData.email)) {
      toast.error("Invalid email format");
      isValid = false;
    }

    if (!formData.subject.trim()) {
      toast.error("Subject is required");
      isValid = false;
    }

    if (!formData.message.trim()) {
      toast.error("Message is required");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await axios.post(
          " https://backend.shnsm1.com/api/contact/send-contact",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        toast.success("Message sent successfully!");

        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      } catch (error) {
        toast.error("Failed to send message. Please try again.");
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="contact-container" id="contact">
      <h1 className="title-heading">Contact Us</h1>
      <div className="contact-wrapper">
        <div className="contact-info1 ">
          <h1> Get In Touch</h1>
          <br></br>
          <div className="info-item">
            <FaPhoneAlt className="icon" /> <p>+91-7995147848</p>
          </div>
          <div className="info-item">
            <IoMdMail className="icon" />{" "}
            <p>
              samrathomeneeds<br></br>andsupermarket@gmail.com
            </p>
          </div>
          <div className="info-item">
            <FaAddressCard className="icon" />{" "}
            <p>
              Near Bullet Showroom, <br></br>Guntur Road, Miryalaguda, <br></br>
              Telangana 508207
            </p>
          </div>
        </div>

        <div className="contact-form">
          <h1>Send a Message</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Full Name"
              className="form-input"
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
              className="form-input"
            />
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              placeholder="Mobile Number"
              className="form-input"
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Message"
              className="form-textarea"
            ></textarea>
            <button type="submit" className="form-btn">
              Submit
            </button>
          </form>
        </div>

        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3818.335963893892!2d79.55647927515302!3d16.85926718394065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTbCsDUxJzMzLjQiTiA3OcKwMzMnMzIuNiJF!5e0!3m2!1sen!2sin!4v1732280489322!5m2!1sen!2sin"
            width="100%"
            height="400"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="location-map"
          ></iframe>
                  
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
