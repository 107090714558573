// ImageModal.js
import React from 'react';

const ImageModal = ({ imageUrl, title, onClose }) => {
    return (
        <div className="modal image-modal" onClick={onClose}>
            <div className="modal-content image-modal-content" onClick={e => e.stopPropagation()}>
                <span className="close-button" onClick={onClose}>&times;</span>
                <h3>{title}</h3>
                <div className="image-container">
                    <img
                        src={imageUrl}
                        alt={title}
                        onError={(e) => {
                            e.target.src = 'placeholder-image-url';
                            e.target.alt = 'Image not found';
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ImageModal;