import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import "./Reward.css"; // Import the CSS file

const Reward = () => {
  return (
    <div>
      <h1 className="head11">REWARDS</h1>
    <div className="Reward">
      <Carousel
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={3000}
        showStatus={false}
      >
        {/* Slide 1 */}
        <div className="container">
          <div className="textSection">
            <h1 className="heading">Sales Executive</h1>
            <pre className="paragraph">
              Members: 1 × 35 = 35 <br />
              Income per Member: ₹4,000 <br />
              Total Income: 35 × ₹4,000 = ₹140,000 <br />
              Profit Percentage: 3.5% <br />
              Total Profit: 3.5% × ₹140,000 = ₹4,900
            </pre>
            <h2>
              Rewards:
            </h2>
              <h3>
                2 Grams of Gold <br />
                Ramoji Film City Tour
              </h3>
          </div>
          <div className="imageSection">
            <img
              src="\Reward\ramoji film city.jpg"
              alt="Illustration 1"
              className="image"
            />
          </div>
        </div>

        <div className="container">
          <div className="textSection">
            <h1 className="heading">Ruby Executive</h1>
            <pre className="paragraph">
              Members: 35 × 35 = 1,225 <br />
              Income per Member: ₹4,000 <br />
              Total Income: 1,225 × ₹4,000 = ₹4,900,000 <br />
              Profit Percentage: 1% <br />
              Total Profit: 1% × ₹4,900,000 = ₹49,000
            </pre>
            <h2>
              Rewards:
            </h2>
              <h3>
                Ruby Winners within 45 Days Receive: <br />2 Grams of Gold &
                Ramoji Film City Tour
              </h3>
          </div>
          <div className="imageSection">
            <img
              src="\Reward\gold.jpg"
              alt="Illustration 1"
              className="image"
            />
          </div>
        </div>

        {/* Slide 2 */}
        <div className="container">
          <div className="textSection">
            <h1 className="heading">Silver Executive </h1>
            <pre className="paragraph">
              Members: 1,225 × 35 = 42,875 <br />
              Income per Member: ₹4,000
              <br /> Total Income: 42,875 × ₹4,000 = ₹171,500,000
              <br /> Profit Percentage: 0.4%
              <br />
              Total Profit: 0.4% × ₹171,500,000 = ₹686,000
            </pre>
            <h2>
              Rewards:
            </h2>
              <h3>
                Silver Winners within 30 Days Receive: 10 Grams of Gold 50 Tola
                Silver
              </h3>
          </div>
          <div className="imageSection">
            <img src="/Reward/goa.jpg" alt="Illustration 2" className="image" />
          </div>
        </div>

        {/* Slide 3 */}
        <div className="container">
          <div className="textSection">
            <h1 className="heading">Gold Executive</h1>
            <p className="paragraph">
              Members: 42,875 × 35 = 1,500,625
              <br /> Income per Member: ₹4,000
              <br /> Total Income: 1,500,625 × ₹4,000 = ₹6,002,500,000
              <br /> Profit Percentage: 0.2% <br />
              Total Profit: 0.2% × ₹6,002,500,000 = ₹12,005,000
            </p>
            <h2>Rewards:</h2>
            <h3>
              Gold Winners within 30 Days Receive:
              <br /> Maruti Suzuki Alto 800 Car <br /> An Honorary Title/Award
            </h3>
          </div>
          <div className="imageSection">
            <img
              src="\Reward\Maruti-Suzuki-Alto-Right-Front-Three-Quarter-154833.jpg"
              alt="Illustration 3"
              className="image"
            />
          </div>
        </div>

        {/* Slide 4 */}
        <div className="container">
          <div className="textSection">
            <h1 className="heading">Diamond Executive</h1>
            <p className="paragraph">
              Members: 1,500,625 × 35 = 52,521,875
              <br />
              Income per Member: ₹4,000
              <br />
              Total Income: 52,521,875 × ₹4,000 = ₹210,087,500,000
              <br />
              Profit Percentage: 0.1%
              <br />
              Total Profit: 0.1% × ₹210,087,500,000 = ₹210,087,500
            </p>
            <h2>Rewards:</h2>
            <h3>
              Diamond Winners within 30 Days Receive:
              <br /> 15 Lacks worth plot <br /> Abroad Tour
            </h3>
          </div>
          <div className="imageSection">
            <img
              src="\Reward\3d-icon-traveling-vacation.jpg"
              alt="Illustration 2"
              className="image"
            />
          </div>
        </div>
      </Carousel>
    </div>
    </div>
  );
};

export default Reward;
