import React, { useContext, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'; // Import Notification Icon
import { StateContext } from '../../../contexts/ContextProvider';

const AdminTopbar = () => {
  const { adminUserName, setAdminUserName } = useContext(StateContext);

  // Fetch admin details from localStorage on mount
  useEffect(() => {
    const storedAdminUserName = localStorage.getItem('adminUserName');
    if (storedAdminUserName) setAdminUserName(storedAdminUserName);
  }, [setAdminUserName]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={2} backgroundColor="#fff">
      {/* Admin Greeting Message */}
      <Typography variant="h1" style={{ color: 'black' }}>
      Welcome! {adminUserName || 'Admin'}, 
      </Typography>

      {/* Icons Container */}
      <Box display="flex" alignItems="center">
        {/* Notification Icon with Black Color */}
        <IconButton>
          <NotificationsOutlinedIcon style={{ color: 'black' }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default AdminTopbar;
