import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  HomeOutlined,
  Email,
  LocalGroceryStore,
  PersonOutline,
  Groups,
  ReceiptLong,
  PieChartOutline,
  Timeline,
  InsertDriveFile,
  EmojiEvents,
  AccountBalanceWallet,
  Message,
  AccountCircle,
  AccountBalance,
  Password,
  Logout,
  ExpandLess,
  ExpandMore,
  MenuOutlined,
} from "@mui/icons-material";
import logo from "../../../Assets/Shnsm1.png";
import { StateContext } from "../../../contexts/ContextProvider";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false); // Default closed for mobile
  const [selected, setSelected] = useState("Dashboard");
  const [openMenu, setOpenMenu] = useState({});
  const { setFlag } = useContext(StateContext);
  const navigate = useNavigate();

  // Check if the screen size is small
  const isMobile = useMediaQuery("(max-width:768px)");

  const handleLogout = () => {
    setFlag(0);
    navigate("/");
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleSubMenuClick = (menu) => {
    setOpenMenu((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  const menuItems = [
    { title: "Dashboard", to: "/dashboard", icon: <HomeOutlined /> },
    { title: "Welcome Letter", to: "/welcome", icon: <Email /> },
    {
      title: "E-Commerce",
      icon: <LocalGroceryStore />,
      submenu: [{ title: "Shop", to: "/shop", icon: <LocalGroceryStore /> }],
    },
    {
      title: "Network",
      icon: <PersonOutline />,
      submenu: [
        { title: "Level Tree", to: "/leveltree", icon: <PersonOutline /> },
        { title: "My Team", to: "/myteam", icon: <Groups /> },
      ],
    },
    {
      title: "E-Wallet",
      icon: <ReceiptLong />,
      submenu: [
        {
          title: "All Transactions",
          to: "/alltransactions",
          icon: <ReceiptLong />,
        },
        {
          title: "Level Income",
          to: "/levelincome",
          icon: <PieChartOutline />,
        },
        { title: "Fund Request", to: "/fundrequest", icon: <Timeline /> },
      ],
    },
    {
      title: "Rewards",
      icon: <InsertDriveFile />,
      submenu: [
        { title: "Reward Levels", to: "/reward", icon: <EmojiEvents /> },
      ],
    },
    {
      title: "Withdrawals",
      icon: <AccountBalanceWallet />,
      to: "/withdrawls",
    },
    {
      title: "My Account",
      icon: <AccountCircle />,
      submenu: [
        { title: "Bank Details", to: "/bankdetail", icon: <AccountBalance /> },
        { title: "Change Password", to: "/changepassword", icon: <Password /> },
      ],
    },
    { title: "Logout", onClick: handleLogout, icon: <Logout /> },
  ];

  return (
    <div>
      {/* Hamburger Icon */}
      <IconButton
        onClick={toggleDrawer}
        style={{
          position: "relative",
          top: 16,
          left: 16,
          zIndex: 1201,
          color: "#ffffff",
        }}
      >
        <MenuOutlined />
      </IconButton>

      {/* Drawer */}
      <Drawer
        variant={isMobile ? "temporary" : "persistent"}
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer}
        sx={{
          width: isMobile ? 240 : isOpen ? 260 : 0,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 260,
            boxSizing: "border-box",
            backgroundColor: "#333333",
            color: "#ffffff",
          },
        }}
      >
        <div
          style={{
            padding: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={logo}
            alt="logo"
            width="80px"
            style={{ borderRadius: "50%" }}
          />
        </div>
        <List>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem
                button
                onClick={
                  item.submenu
                    ? () => handleSubMenuClick(item.title)
                    : item.onClick
                    ? () => {
                        item.onClick();
                        if (isMobile) toggleDrawer();
                      }
                    : () => {
                        navigate(item.to);
                        if (isMobile) toggleDrawer();
                      }
                }
                style={{
                  backgroundColor:
                    selected === item.title ? "#424242" : "transparent",
                  color: "#ffffff",
                }}
              >
                <ListItemIcon style={{ color: "#ffffff" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} />
                {item.submenu ? (
                  openMenu[item.title] ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItem>
              {item.submenu && (
                <Collapse
                  in={openMenu[item.title]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List disablePadding>
                    {item.submenu.map((subItem, subIndex) => (
                      <ListItem
                        button
                        key={subIndex}
                        style={{
                          paddingLeft: "32px",
                          backgroundColor:
                            selected === subItem.title
                              ? "#616161"
                              : "transparent",
                          color: "#ffffff",
                        }}
                        onClick={() => {
                          navigate(subItem.to);
                          if (isMobile) toggleDrawer();
                        }}
                      >
                        <ListItemIcon style={{ color: "#ffffff" }}>
                          {subItem.icon}
                        </ListItemIcon>
                        <ListItemText primary={subItem.title} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default Sidebar;
