import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { BsCheck } from 'react-icons/bs';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { themeColors } from '../../../data/dummy';
import { StateContext } from '../../../contexts/ContextProvider';

import './ThemeSettings.css';

const ThemeSettings = () => {
  const { setColor, setMode, currentMode, currentColor, setThemeSettings } = StateContext();

  return (
    <div className="theme-settings-container">
      <div className={`theme-settings-panel ${currentMode === 'Dark' ? 'dark' : ''}`}>
        <div className="theme-settings-header">
          <p className="theme-settings-title">Settings</p>
          <button
            type="button"
            onClick={() => setThemeSettings(false)}
            className="theme-settings-close-btn"
          >
            <MdOutlineCancel />
          </button>
        </div>

        <div className="theme-settings-content">
          <div className="theme-option">
            <p className="theme-option-label">Theme Option</p>
            <div className="theme-radio">
              <input
                type="radio"
                id="light"
                name="theme"
                value="Light"
                onChange={setMode}
                checked={currentMode === 'Light'}
              />
              <label htmlFor="light">Light</label>
            </div>
            <div className="theme-radio">
              <input
                type="radio"
                id="dark"
                name="theme"
                value="Dark"
                onChange={setMode}
                checked={currentMode === 'Dark'}
              />
              <label htmlFor="dark">Dark</label>
            </div>
          </div>

          <div className="theme-colors">
            <p className="theme-color-label">Theme Colors</p>
            <div className="theme-color-picker">
              {themeColors.map((item, index) => (
                <TooltipComponent key={index} content={item.name} position="TopCenter">
                  <div className="theme-color-item">
                    <button
                      type="button"
                      className="theme-color-btn"
                      style={{ backgroundColor: item.color }}
                      onClick={() => setColor(item.color)}
                    >
                      <BsCheck
                        className={`theme-color-check ${item.color === currentColor ? 'visible' : 'hidden'}`}
                      />
                    </button>
                  </div>
                </TooltipComponent>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeSettings;
